import React, {useContext, useState, useEffect} from "react";
import { UserContext } from "../Context/UserContext";

function TeamTrends({relevantGame, ratingTimeframe}) {
    const {state} = useContext(UserContext)
    //console.log('ratingTimeFrame on Trends', ratingTimeframe)
    //console.log('relGame', relevantGame)
    //console.log('trends', state.teamTrends)
    const [teamWithMoreERH, setTeamWithMoreERH] = useState("")
    //console.log('team with more ERH', teamWithMoreERH)
    const [teamWithMoreGRH, setTeamWithMoreGRH] = useState("")
    //console.log('team with more GRH',teamWithMoreGRH)
    const [teamWithHigherMR, setTeamWithHigherMR] = useState("")
    const [dataTimeframe, setDataTimeFrame] = useState("three_year")
    //console.log('dataTimeFrame', dataTimeframe)

    const homeTeamTrends = state.teamTrends?.find((trend) => String(trend?.team) === String(relevantGame?.home_team))
    //console.log('homeTeamTrends', homeTeamTrends)
    const awayTeamTrends = state.teamTrends.find((trend) => String(trend?.team) === String(relevantGame?.away_team))
    //console.log('awayTeamTrends', awayTeamTrends)

    const awayAvgMatchupRating = `away_team_${dataTimeframe}_avg_hitter_matchup_rating`
    const homeAvgMatchupRating = `home_team_${dataTimeframe}_avg_hitter_matchup_rating`

    const awayERH = `away_team_${dataTimeframe}_erh`
    const homeERH = `home_team_${dataTimeframe}_erh`

    const awayGRH = `away_team_${dataTimeframe}_grh`
    const homeGRH = `home_team_${dataTimeframe}_grh`

    useEffect(() => {
        function checkTimeFrame() {
            if (ratingTimeframe) {
                setDataTimeFrame("current_season")
            }
            else {
                setDataTimeFrame("three_year")
            }
        }

        function checkEliteDifference() {
            if (relevantGame[awayERH] > relevantGame[homeERH]) {
                setTeamWithMoreERH(relevantGame?.away_team)
            }
            else if (relevantGame[homeERH] > relevantGame[awayERH]) {
                setTeamWithMoreERH(relevantGame?.home_team)
            }
            //console.log('team with more ERH in check Elite', teamWithMoreERH)
        }

        function checkGreatDifference() {
            if (relevantGame[awayGRH] > relevantGame[homeGRH]) {
                setTeamWithMoreGRH(relevantGame?.away_team)
            }
            else if (relevantGame[homeGRH] > relevantGame[awayGRH]) {
                setTeamWithMoreGRH(relevantGame?.home_team)
            }
            //console.log('team with more GRH in check Great', teamWithMoreGRH)
        }

        function checkMatchupRatingDifference() {
            if (relevantGame[awayAvgMatchupRating] > relevantGame[homeAvgMatchupRating]) {
                setTeamWithHigherMR(relevantGame?.away_team)
            }
            else if (relevantGame[homeAvgMatchupRating] > relevantGame[awayAvgMatchupRating]) {
                setTeamWithHigherMR(relevantGame?.home_team)
            }
        }

        checkTimeFrame()
        checkEliteDifference()
        checkGreatDifference()
        checkMatchupRatingDifference()
    }, [relevantGame, teamWithMoreERH, teamWithMoreGRH, teamWithHigherMR, ratingTimeframe, awayAvgMatchupRating, homeAvgMatchupRating, awayERH, homeERH, awayGRH, homeGRH])

    function renderMoreRatedHittersWinPcts(trend, field_suffix) {
        const moreRatedHittersWinPct = `more_${dataTimeframe}_${field_suffix}_win_pct`
        const todayDiffWinPct = `today_diff_${dataTimeframe}_${field_suffix}_win_pct`
        const numGamesMoreHitters = `num_games_with_more_${dataTimeframe}_${field_suffix}`
        const numGamesTodayDiff = `num_games_with_today_diff_${dataTimeframe}_${field_suffix}`
        const todayDiff = `${dataTimeframe}_${field_suffix}_diff_from_opp`

        return (
            <div>
                <li>{trend.team} Won {trend[moreRatedHittersWinPct]}% Games With More {field_suffix.toUpperCase()} in 2024 | Sample Size: {trend[numGamesMoreHitters]} Games</li>
                {trend[todayDiff] > 1 && (<li>{trend.team} Won {trend[todayDiffWinPct]}% Games With A +{trend[todayDiff]} {field_suffix.toUpperCase()} in 2024 | Sample Size: {trend[numGamesTodayDiff]} Games</li>)}
            </div>
        )
    }

    function renderHigherMatchupRatingWinPct(trend) {
        const betterWinPct = `better_${dataTimeframe}_matchup_rating_win_pct`
        const sampleSize = `num_games_with_higher_${dataTimeframe}_avg_hitter_matchup_rating`
        return (
            <div>
                <li>{trend.team} Won {trend[betterWinPct]}% Games With A Higher Matchup Rating in 2024 | Sample Size: {trend[sampleSize]} Games</li>
            </div>
        )
    }

    function renderBothTeamInsights() {
        return (
            <div style={{textAlign: "center", listStyle: "none", marginTop: "-1rem"}}>
                <li>{relevantGame?.away_team} Average Matchup Rating: {relevantGame[awayAvgMatchupRating].toFixed(3)}</li>
                <li>{relevantGame?.home_team} Average Matchup Rating: {relevantGame[homeAvgMatchupRating].toFixed(3)}</li>
                {teamWithHigherMR === relevantGame.away_team && renderHigherMatchupRatingWinPct(awayTeamTrends)}
                {teamWithHigherMR === relevantGame.home_team && renderHigherMatchupRatingWinPct(homeTeamTrends)}
                <br />
                <li>{relevantGame?.away_team} Has {relevantGame[awayERH]} Elite Rated Hitters (ERH), {relevantGame?.home_team} Has {relevantGame[homeERH]}</li>
                {teamWithMoreERH === relevantGame.away_team && renderMoreRatedHittersWinPcts(awayTeamTrends, "erh")}
                {teamWithMoreERH === relevantGame.home_team && renderMoreRatedHittersWinPcts(homeTeamTrends, "erh")}
                <br />
                <li>{relevantGame?.away_team} Has {relevantGame[awayGRH]} Great Rated Hitters (GRH), {relevantGame?.home_team} Has {relevantGame[homeGRH]}</li>
                {teamWithMoreGRH === relevantGame.away_team && renderMoreRatedHittersWinPcts(awayTeamTrends, "grh")}
                {teamWithMoreGRH === relevantGame.home_team && renderMoreRatedHittersWinPcts(homeTeamTrends, "grh")}
            </div>
        )
    }

    return (
        <div>
            <h2 style={{color: "navy", fontSize: "large", textAlign: "center"}}>Team Insights</h2>
            {renderBothTeamInsights()}
        </div>
    )
}

export default TeamTrends
import React, {useContext, useState, useEffect} from "react";
import { UserContext } from '../Context/UserContext';

function HitterResultsTable({ratingTimeframe, ratingTier, defaultOrCurrent}) {
    const {state} = useContext(UserContext);
    const [dataTimeframe, setDataTimeframe] = useState("three_year")

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "current_season" : "three_year")
    }, [ratingTimeframe])

    return (
        <div className="hitterResultsContainer">
            {state.singleHitter[`elite_rating_percentages_${dataTimeframe}`][0]?.number_elite_ratings < 10 ? 
            (<h2>Last {state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.number_elite_ratings} Games with {defaultOrCurrent} {ratingTier} Ratings</h2>)
            : (<h2>Last 10 Games with {defaultOrCurrent} {ratingTier} Ratings</h2>)}
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Matchup Rating</th>
                        <th>PA</th>
                        <th>H</th>
                        <th>1B</th>
                        <th>2B</th>
                        <th>HR</th>
                        <th>R</th>
                        <th>RBI</th>
                        <th>Hits, Runs, RBI</th>
                        <th>Total Bases</th>
                        {/* <th>FD Pts</th>
                        <th>DK Pts</th> */}
                    </tr>
                </thead>
                <tbody>
                    {state.singleHitter?.[`last_10_results_${dataTimeframe}`]?.map((stat) => {
                        return (
                            <tr key={stat.date}>
                                <td>{stat.date}</td>
                                <td>{stat.rounded_matchup_rating}</td>
                                <td>{stat.plate_appearances}</td>
                                <td>{stat.hits}</td>
                                <td>{stat.singles}</td>
                                <td>{stat.doubles}</td>
                                <td>{stat.home_runs}</td>
                                <td>{stat.runs}</td>
                                <td>{stat.rbi}</td>
                                <td>{stat.hits_runs_rbi}</td>
                                <td>{stat.total_bases}</td>
                                {/* <td>{stat.fanduel_points}</td>
                                <td>{stat.draftkings_points}</td> */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default HitterResultsTable
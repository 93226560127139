import React, {useContext, useMemo, useState, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import NotPremium from "./NotPremium";
import "../styles/pitcherTrendsTable.css"
import {useNavigate} from "react-router-dom"

function BadPitcherStrikeoutRatingTrendsTable({ratingTimeframe}) {
    const {state, dispatch} = useContext(UserContext);
    //console.log('state', state)
    const navigate = useNavigate()
    const isUser = state.loggedIn ? true : false
    const [sortDirection, setSortDirection] = useState(false)
    const [sortState, setSortState] = useState("")
    const [dataTimeframe, setDataTimeframe] = useState("ThreeYear")

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "CurrentSeason" : "ThreeYear")
    }, [ratingTimeframe])
    //console.log('datatimeframe', dataTimeframe)

    const trendsByTimeFrame = state?.[`badPitcherStrikeoutRatingTrends${dataTimeframe}`]
    const relDailyPitcherByTimeFrame = state?.[`dailyPitcher${dataTimeframe}Stats`]

    function getStrikeoutRating(pitID) {
        const rel_pitcher = relDailyPitcherByTimeFrame?.find((stat) => stat.pitcher_id === pitID)
        //console.log('rel_pitcher', rel_pitcher)
        const strikeoutRateStyles = {
            backgroundColor: (rel_pitcher?.avg_hitter_matchup_k_pct <= 20.0 ? 'lightpink' : '')
        }

        return {ratingStyle: strikeoutRateStyles, rating: rel_pitcher.avg_hitter_matchup_k_pct.toFixed(2)}
    }

    const pitcherRatings = useMemo(() => {
        return trendsByTimeFrame.map((trend) => ({
            id: trend.id,
            pitcherID: trend.pitcher_id,
            name: trend.pitcher_name,
            team: trend.team,
            strikeoutRating: getStrikeoutRating(trend.pitcher_id).rating,
            numberGames: trend.num_games_sample_size,
            pctThreeOrLessK: trend.pct_three_or_less_strikeouts_sr_category,
            pctFourOrLessK: trend.pct_four_or_less_strikeouts_sr_category,
            pctFiveOrLessK: trend.pct_five_or_less_strikeouts_sr_category,
            pctSixOrLessK: trend.pct_six_or_less_strikeouts_sr_category,
            pctSevenOrLessK: trend.pct_seven_or_less_strikeouts_sr_category,
            backgroundColor: getStrikeoutRating(trend.pitcher_id).ratingStyle
        }));
    }, [trendsByTimeFrame])

    //console.log(pitcherRatings)
    const [filteredPitchers, setFilteredPitchers] = useState(pitcherRatings)

    useEffect(() => {
        setFilteredPitchers(pitcherRatings)
    }, [pitcherRatings])

    function handleSort(e) {
        setSortState(e.target.dataset.sortKey)
        setSortDirection((prevDirection) => !prevDirection)
    }

    const sortedTrends = useMemo(() => {
        const sortedStats = [...(filteredPitchers)]?.sort((a, b) => {
            const aValue = a?.[sortState] || "";
            const bValue = b?.[sortState] || "";

            if (typeof aValue === "string" && typeof bValue === "string") {
                return sortDirection 
                    ? aValue.localeCompare(bValue)  // Ascending order for strings
                    : bValue.localeCompare(aValue); // Descending order for strings
            }

            const numA = Number(aValue) || 0;
            const numB = Number(bValue) || 0;
            return sortDirection ? numA - numB : numB - numA;
        });
        //console.log('sortedStats', sortedStats)
        //console.log('sortState', sortState)
        //console.log('sortDirection', sortDirection)
        return sortedStats;
    }, [filteredPitchers, sortDirection, sortState])
    console.log('sortedTrends', sortedTrends)

    function handleSearch(e) {
        const inputValue = e.target.value.toLowerCase('')
        if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            setFilteredPitchers(pitcherRatings)
        }
        else {
            setFilteredPitchers(pitcherRatings.filter((rating) => rating.name.toLowerCase().includes(inputValue)))
        }
    }

    function fetchsinglePitcherData(pitcherID) {
        fetch(`/pitchers/${pitcherID}`)
            .then(res => res.json())
            .then(data => {
                if (data) {
                    dispatch({type: "fetchSinglePitcher", payload: data})
                }
                else {
                    console.error("Error: Pitcher data not found")
                }
            })
    }

    function navigateToPitcherPage(pitchername, pitcherID) {
        state.singlePitcher = []
        const encodedName = encodeURIComponent(pitchername)
        fetchsinglePitcherData(pitcherID)
        navigate(`/pitcher/${encodedName}`)
        window.scrollTo(0, 0)
    }

    if (isUser)
        return (
        <div className="pitcherStrikeoutTrendsContainerTable">
            <div className="searchBar">
                <label>Search A Pitcher: </label>
                <input type="text" placeholder="Ex: Justin Steele" onChange={handleSearch}></input>
            </div>
            <br />
            <table>
                <thead>
                    <tr>
                        <th onClick={handleSort} data-sort-key="name">Name<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="team">Team<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="strikeoutRating">Strikeout Rate<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="numberGames">Games<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctThreeOrLessK">Under 3.5 K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctFourOrLessK">Under 4.5 K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctFiveOrLessK">Under 5.5 K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctSixOrLessK">Under 6.5 K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctSevenOrLessK">Under 7.5 K<span>↕️</span></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedTrends.map((trend) => {
                        const teamStyles = {backgroundColor: state.teamColors[trend.team].primary || "transparent"}
                        const three_or_less_k_styles = {
                            color: (trend?.pctThreeOrLessK >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctThreeOrLessK <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const four_or_less_k_styles = {
                            color: (trend?.pctFourOrLessK >= 60.00 ? 'rgba(0, 105, 0)' : (trend?.pctFourOrLessK <= 45.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const five_or_less_k_styles = {
                            color: (trend?.pctFiveOrLessK >= 70.00 ? 'rgba(0, 105, 0)' : (trend?.pctFiveOrLessK <= 50.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const six_or_less_k_styles = {
                            color: (trend?.pctSixOrLessK >= 70.00 ? 'rgba(0, 105, 0)' : (trend?.pctSixOrLessK <= 50.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const seven_or_less_k_styles = {
                            color: (trend?.pctSevenOrLessK >= 80.00 ? 'rgba(0, 105, 0)' : (trend?.pctSevenOrLessK <= 60.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }

                        return (
                            <tr key={trend.id}>
                                <td onClick={() => navigateToPitcherPage(trend.name, trend.pitcherID)}>{trend.name}</td>
                                <td style={teamStyles}>{trend.team}</td>
                                <td style={trend.backgroundColor}>{trend.strikeoutRating}%</td>
                                <td>{trend.numberGames}</td>
                                <td style={three_or_less_k_styles}>{trend.pctThreeOrLessK}%</td>
                                <td style={four_or_less_k_styles}>{trend.pctFourOrLessK}%</td>
                                <td style={five_or_less_k_styles}>{trend.pctFiveOrLessK}%</td>
                                <td style={six_or_less_k_styles}>{trend.pctSixOrLessK}%</td>
                                <td style={seven_or_less_k_styles}>{trend.pctSevenOrLessK}%</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
        )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
            )
        }
}

export default BadPitcherStrikeoutRatingTrendsTable
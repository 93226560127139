import React, {useContext} from "react";
import { UserContext } from '../Context/UserContext';

function ElitePitcherMatchupRatingResults({backendDataTimeframe, ratingTier, defaultOrCurrent}) {
    const {state} = useContext(UserContext);
    //console.log('backendDataTimeframe on PitcherMatchupRatingResults', backendDataTimeframe)

    function renderHeader() {
        if (state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_matchup_rating`][0]?.number_elite_ratings < 10) {
            return <h2>Last {state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_matchup_rating`][0]?.number_elite_ratings} Games with {defaultOrCurrent} {ratingTier} Ratings</h2>
        }
        else if (state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_matchup_rating`][0]?.number_elite_ratings >= 10) {
            return <h2>Last 10 Games with {defaultOrCurrent} {ratingTier} Ratings</h2>
        }
        else if (state.singlePitcher?.[`poor_rating_percentages_${backendDataTimeframe}_matchup_rating`][0]?.number_poor_ratings < 10) {
            return <h2>Last {state.singlePitcher?.[`poor_rating_percentages_${backendDataTimeframe}_matchup_rating`][0]?.number_poor_ratings} Games with {defaultOrCurrent} {ratingTier} Ratings</h2>
        }
        else return <h2>Last 10 Games with {defaultOrCurrent} {ratingTier} Ratings</h2>
    }

    return (
        <div className="pitcherResultsContainer">
            {renderHeader()}
            <table>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Matchup Rating</th>
                        <th>IP</th>
                        <th>H</th>
                        <th>R</th>
                        <th>ER</th>
                        <th>HR</th>
                        <th>BB</th>
                        <th>K</th>
                        <th>Win</th>
                        <th>Loss</th>
                        {/* <th>FD Pts</th>
                        <th>DK Pts</th> */}
                    </tr>
                </thead>
                <tbody>
                    {state.singlePitcher?.[`last_10_results_${backendDataTimeframe}_matchup_rating`]?.map((stat) => {
                        return (
                            <tr key={stat.date}>
                                <td>{stat.date}</td>
                                <td>{stat.rounded_matchup_rating}</td>
                                <td>{stat.innings_pitched}</td>
                                <td>{stat.hits}</td>
                                <td>{stat.runs}</td>
                                <td>{stat.earned_runs}</td>
                                <td>{stat.home_runs}</td>
                                <td>{stat.walks}</td>
                                <td>{stat.strikeouts}</td>
                                <td>{stat.win}</td>
                                <td>{stat.loss}</td>
                                {/* <td>{stat.fanduel_points}</td>
                                <td>{stat.draftkings_points}</td> */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default ElitePitcherMatchupRatingResults
import React from "react";

function RatingDropDown({ratingTimeframe, handleChange}) {

    return (
        <div className="ratings-dropdown">
            <label>Select Rating Timeframe: </label>
            <select onChange={handleChange} value={ratingTimeframe ? "Current Season Only" : "Default (Last 3 Seasons)"}>
                <option>Default (Last 3 Seasons)</option>
                <option>Current Season Only</option>
            </select>
        </div>
    )
}

export default RatingDropDown
import React, {useEffect, useState} from "react";
import ReactGA from 'react-ga';
import {Routes, Route, useLocation } from 'react-router-dom'
import { UserProvider } from '../Context/UserContext';
import Login from "./Login";
import Signup from "./Signup";
import NavBar from "./NavBar";
import FAQs from "./FAQs";
import About from "./About";
import HitterRatings from "./HitterRatings";
import SingleGame from "./SingleGame";
import PitcherRatings from "./PitcherRatings";
import Checkout from "./Checkout";
import GetPasswordResetLink from "./GetPasswordResetLink";
import PasswordRestForm from "./PasswordRestForm";
import NewHome from "./NewHome";
import BattersBoxBreakdown from "./BattersBoxBreakdown";
import NewFor2024 from "./NewFor2024";
import Highlights2023 from "./Highlights2023";
import Footer from "./Footer";
// import Picks from "./Picks";
import UserIcon from "./UserIcon";
// import UserFavorites from "./UserFavorites";
import UserSettings from "./UserSettings";
import TermsofSerivce from "./TermsofService"
import PrivacyPolicy from "./PrivacyPolicy"
import AnnouncementPopUp from "./AnnouncementPopUp";
// import SalePopUp from "./SalePopUp";
//import FreePopUp from "./FreePopUp";
import Cookies from 'js-cookie'
import HitterPage from "./HitterPage";
import PitcherPage from "./PitcherPage";
import HitterTrendsContainer from "./HitterTrendsContainer";
import PitcherTrendsContainer from "./PitcherTrendsContainer";

function App() {
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [announcementAcknowledged, setAnnouncementAcknowledged] = useState(false);
  //console.log('announcementAcknowledged', announcementAcknowledged)
  const TRACKING_ID = "UA-262458088-1";
  ReactGA.initialize(TRACKING_ID);

  const location = useLocation();

  const isNotHomePage = location.pathname !== '/';
  const isNotWelcome = location.pathname !== '/welcome'

  useEffect(() => {
    const termsAcknowledged = Cookies.get('termsAcknowledged')
    if (termsAcknowledged) {
      setAnnouncementAcknowledged(true)
    }
  }, [])

  return (
    <div className="App">
      <UserProvider>
        {isNotHomePage && isNotWelcome && <NavBar/>}
        <UserIcon />
        {!announcementAcknowledged && <AnnouncementPopUp onAcknowledge={() => setAnnouncementAcknowledged(true)} />}
        {/* {announcementAcknowledged && <FreePopUp />} */}
        {/* {announcementAcknowledged && <SalePopUp />} */}
        <Routes>
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/" element={<NewHome />} />
          <Route exact path="/reset-password/:token" element={<PasswordRestForm />} />
          <Route exact path="/password-reset" element={<GetPasswordResetLink />} />
          {/* <Route exact path="/user/favorites" element={<UserFavorites />} /> */}
          <Route exact path="/ratings/hitters" element={<HitterRatings />} />
          <Route exact path="/ratings/pitchers" element={<PitcherRatings />} />
          <Route exact path="/hitter/:name" element={<HitterPage />} />
          <Route exact path="/pitcher/:name" element={<PitcherPage />} />
          <Route exact path="/trends/hitters" element={<HitterTrendsContainer />} />
          <Route exact path="/trends/pitchers" element={<PitcherTrendsContainer />} />
          <Route exact path="/login" element={<Login email={email} setEmail={setEmail} password={password} setPassword={setPassword} />}/>
          <Route exact path="/signup" element={<Signup firstName={firstName} setFirstName={setFirstName} lastName={lastName} setLastName={setLastName} email={email} setEmail={setEmail} password={password} setPassword={setPassword} passwordConfirmation={passwordConfirmation} setPasswordConfirmation={setPasswordConfirmation} />}/>
          <Route exact path="/FAQs" element={<FAQs />}/>
          <Route exact path="/game/:away_team/vs/:home_team" element={<SingleGame />}/>
          <Route exact path="/about" element={<About />}/>
          <Route exact path="/guide" element={<BattersBoxBreakdown />}/>
          <Route exact path="/new-features" element={<NewFor2024 />}/>
          <Route exact path="/highlights/2023" element={<Highlights2023 />}/>
          {/* <Route exact path="/picks" element={<Picks />}/> */}
          <Route exact path="/user/settings" element={<UserSettings />} />
          <Route exact path="/terms-of-service" element={<TermsofSerivce />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </UserProvider>
    </div>
  );
}

export default App;
import React, {useContext, useState} from "react";
import { UserContext } from '../Context/UserContext';
import NotPremium from "./NotPremium";
import Logos from "./Logos";
import GameBar from "./GameBar";
import RatingDropDown from "./RatingDropDown";
import GoodHitterTrendsTable from "./GoodHitterTrendsTable";
import ColorLabels from "./ColorLabels";
import BadHitterTrendsTable from "./BadHitterTrendsTable";

function HitterTrendsContainer() {
    const {state} = useContext(UserContext);
    //console.log('singleHitterState', state.singleHitter)
    const [ratingTimeframe, setRatingTimeframe] = useState(false) //true is current season, false is default
    const [eliteGreateRatings, setEliteGreatRatings] = useState(true) //true is elite/great/above avg hitters, false is avg and poor hitters
    const isUser = state.loggedIn ? true : false

    function handleChange() {
        setRatingTimeframe(prevTimeframe => !prevTimeframe)
    }

    function handleEliteGreatChange() {
        setEliteGreatRatings(prevEliteGreatRatings => !prevEliteGreatRatings)
    }


    if (isUser)
    return (
        <div>
            <Logos />
            <br />
            <GameBar />
            <RatingDropDown ratingTimeframe={ratingTimeframe} handleChange={handleChange}/>
            <div className="ratings-dropdown">
                <label>Select Trend: </label>
                <select onChange={handleEliteGreatChange} value={eliteGreateRatings ? "Over Trends" : "Under Trends"}>
                    <option>Over Trends</option>
                    <option>Under Trends</option>
                </select>
            </div>
            <ColorLabels />
            <br />
            <div style={{textAlign: "center", listStyle: "none"}}>
                <li>Note: All trends are based on today's matchup rating category.</li>
                <li>Example: Number of games with an elite rating or great rating, etc.</li>
            </div>
            {eliteGreateRatings ? <GoodHitterTrendsTable ratingTimeframe={ratingTimeframe} /> : <BadHitterTrendsTable ratingTimeframe={ratingTimeframe} />}
        </div>
    )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
            )
        }
}

export default HitterTrendsContainer
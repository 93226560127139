import React, {useContext, useMemo, useState, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import NotPremium from "./NotPremium";
import "../styles/pitcherTrendsTable.css"
import {useNavigate} from "react-router-dom"

function GoodPitcherMatchupRatingTrendsTable({ratingTimeframe}) {
    const {state, dispatch} = useContext(UserContext);
    //console.log('state', state)
    const navigate = useNavigate()
    const isUser = state.loggedIn ? true : false
    const [sortDirection, setSortDirection] = useState(false)
    const [sortState, setSortState] = useState("")
    const [dataTimeframe, setDataTimeframe] = useState("ThreeYear")

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "CurrentSeason" : "ThreeYear")
    }, [ratingTimeframe])
    //console.log('datatimeframe', dataTimeframe)

    const trendsByTimeFrame = state?.[`goodPitcherMatchupRatingTrends${dataTimeframe}`]
    const relDailyPitcherByTimeFrame = state?.[`dailyPitcher${dataTimeframe}Stats`]

    function getMatchupRating(pitID) {
        const rel_pitcher = relDailyPitcherByTimeFrame?.find((stat) => stat.pitcher_id === pitID)
        //console.log('rel_pitcher', rel_pitcher)
        const matchupRatingStyles = {
            backgroundColor: (rel_pitcher?.pitcher_matchup_rating_thirty_percent <= 5.63 ? 'lightblue' : '') || (rel_pitcher?.pitcher_matchup_rating_thirty_percent > 5.63 && rel_pitcher?.pitcher_matchup_rating_thirty_percent <= 5.85 ? 'lightgreen' : '') || (rel_pitcher?.pitcher_matchup_rating_thirty_percent > 5.85 && rel_pitcher?.pitcher_matchup_rating_thirty_percent < 6.05 ? 'lightyellow' : '')
        }

        return {ratingStyle: matchupRatingStyles, rating: rel_pitcher.pitcher_matchup_rating_thirty_percent.toFixed(3)}
    }

    const pitcherRatings = useMemo(() => {
        return trendsByTimeFrame.map((trend) => ({
            id: trend.id,
            pitcherID: trend.pitcher_id,
            name: trend.pitcher_name,
            team: trend.team,
            matchupRating: getMatchupRating(trend.pitcher_id).rating,
            numberGames: trend.num_games_sample_size,
            pctFivePlusIP: trend.pct_five_plus_ip_mr_category,
            pctSixPlusIP: trend.pct_six_plus_ip_mr_category,
            pctSevenPlusIP: trend.pct_seven_plus_ip_mr_category,
            pctFourPlusK: trend.pct_four_plus_strikeouts_mr_category,
            pctFivePlusK: trend.pct_five_plus_strikeouts_mr_category,
            pctSixPlusK: trend.pct_six_plus_strikeouts_mr_category,
            pctSevenPlusK: trend.pct_seven_plus_strikeouts_mr_category,
            pctEightPlusK: trend.pct_eight_plus_strikeouts_mr_category,
            pctNinePlusK: trend.pct_nine_plus_strikeouts_mr_category,
            pctTwoOrLessER: trend.pct_two_or_less_earned_runs_mr_category,
            pctOneOrLessER: trend.pct_one_or_less_earned_runs_mr_category,
            pctZeroER: trend.pct_zero_earned_runs_mr_category,
            pctFiveOrLessHits: trend.pct_five_or_less_hits_mr_category,
            pctFourOrLessHits: trend.pct_four_or_less_hits_mr_category,
            pctThreeOrLessHits: trend.pct_three_or_less_hits_mr_category,
            PctOneOrLessWalks: trend.pct_one_or_less_walks_mr_category,
            pctZeroWalks: trend.pct_zero_walks_mr_category,
            pctWin: trend.pct_win_mr_category,
            backgroundColor: getMatchupRating(trend.pitcher_id).ratingStyle
        }));
    }, [trendsByTimeFrame])

    //console.log(pitcherRatings)
    const [filteredPitchers, setFilteredPitchers] = useState(pitcherRatings)

    useEffect(() => {
        setFilteredPitchers(pitcherRatings)
    }, [pitcherRatings])

    function handleSort(e) {
        setSortState(e.target.dataset.sortKey)
        setSortDirection((prevDirection) => !prevDirection)
    }

    const sortedTrends = useMemo(() => {
        const sortedStats = [...(filteredPitchers)]?.sort((a, b) => {
            const aValue = a?.[sortState] || "";
            const bValue = b?.[sortState] || "";

            if (typeof aValue === "string" && typeof bValue === "string") {
                return sortDirection 
                    ? aValue.localeCompare(bValue)  // Ascending order for strings
                    : bValue.localeCompare(aValue); // Descending order for strings
            }

            const numA = Number(aValue) || 0;
            const numB = Number(bValue) || 0;
            return sortDirection ? numA - numB : numB - numA;
        });
        //console.log('sortedStats', sortedStats)
        //console.log('sortState', sortState)
        //console.log('sortDirection', sortDirection)
        return sortedStats;
    }, [filteredPitchers, sortDirection, sortState])
    console.log('sortedTrends', sortedTrends)

    function handleSearch(e) {
        const inputValue = e.target.value.toLowerCase('')
        if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            setFilteredPitchers(pitcherRatings)
        }
        else {
            setFilteredPitchers(pitcherRatings.filter((rating) => rating.name.toLowerCase().includes(inputValue)))
        }
    }

    function fetchsinglePitcherData(pitcherID) {
        fetch(`/pitchers/${pitcherID}`)
            .then(res => res.json())
            .then(data => {
                if (data) {
                    dispatch({type: "fetchSinglePitcher", payload: data})
                }
                else {
                    console.error("Error: Pitcher data not found")
                }
            })
    }

    function navigateToPitcherPage(pitchername, pitcherID) {
        state.singlePitcher = []
        const encodedName = encodeURIComponent(pitchername)
        fetchsinglePitcherData(pitcherID)
        navigate(`/pitcher/${encodedName}`)
        window.scrollTo(0, 0)
    }

    if (isUser)
        return (
        <div className="pitcherTrendsContainerTable">
            <div className="searchBar">
                <label>Search A Pitcher: </label>
                <input type="text" placeholder="Ex: Shota Imanaga" onChange={handleSearch}></input>
            </div>
            <br />
            <table>
                <thead>
                    <tr>
                        <th onClick={handleSort} data-sort-key="name">Name<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="team">Team<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="matchupRating">Matchup Rating<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="numberGames">Games<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctFivePlusIP">5+ IP<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctSixPlusIP">6+ IP<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctSevenPlusIP">7+ IP<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctFourPlusK">4+ K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctFivePlusK">5+ K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctSixPlusK">6+ K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctSevenPlusK">7+ K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctEightPlusK">8+ K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctNinePlusK">9+ K<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctTwoOrLessER">Under 2.5 ER<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctOneOrLessER">Under 1.5 ER<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctZeroER">Under 0.5 ER<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctFiveOrLessHits">Under 5.5 Hits<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctFourOrLessHits">Under 4.5 Hits<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctThreeOrLessHits">Under 3.5 Hits<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="PctOneOrLessWalks">Under 1.5 Walks<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctZeroWalks">Under 0.5 Walks<span>↕️</span></th>
                        <th onClick={handleSort} data-sort-key="pctWin">Records Win<span>↕️</span></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedTrends.map((trend) => {
                        const teamStyles = {backgroundColor: state.teamColors[trend.team].primary || "transparent"}
                        const five_plus_ip_styles = {
                            color: (trend?.pctFivePlusIP >= 70.00 ? 'rgba(0, 105, 0)' : (trend?.pctFivePlusIP <= 50.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const six_plus_ip_styles = {
                            color: (trend?.pctSixPlusIP >= 60.00 ? 'rgba(0, 105, 0)' : (trend?.pctSixPlusIP <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const seven_plus_ip_styles = {
                            color: (trend?.pctSevenPlusIP >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctSevenPlusIP <= 30.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const four_plus_k_styles = {
                            color: (trend?.pctFourPlusK >= 75.00 ? 'rgba(0, 105, 0)' : (trend?.pctFourPlusK <= 60.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const five_plus_k_styles = {
                            color: (trend?.pctFivePlusK >= 60.00 ? 'rgba(0, 105, 0)' : (trend?.pctFivePlusK <= 50.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const six_plus_k_styles = {
                            color: (trend?.pctSixPlusK >= 55.00 ? 'rgba(0, 105, 0)' : (trend?.pctSixPlusK <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const seven_plus_k_styles = {
                            color: (trend?.pctSevenPlusK >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctSevenPlusK <= 30.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const eight_plus_k_styles = {
                            color: (trend?.pctEightPlusK >= 25.00 ? 'rgba(0, 105, 0)' : (trend?.pctEightPlusK <= 15.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const nine_plus_k_styles = {
                            color: (trend?.pctNinePlusK >= 20.00 ? 'rgba(0, 105, 0)' : (trend?.pctNinePlusK <= 10.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const two_or_less_er_styles = {
                            color: (trend?.pctTwoOrLessER >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctTwoOrLessER <= 30.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_or_less_er_styles = {
                            color: (trend?.pctOneOrLessER >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctOneOrLessER <= 30.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const zero_er_styles = {
                            color: (trend?.pctZeroER >= 25.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroER <= 15.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const five_or_less_hits_styles = {
                            color: (trend?.pctFiveOrLessHits >= 60.00 ? 'rgba(0, 105, 0)' : (trend?.pctFiveOrLessHits <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const four_or_less_hits_styles = {
                            color: (trend?.pctFourOrLessHits >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctFourOrLessHits <= 30.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const three_or_less_hits_styles = {
                            color: (trend?.pctThreeOrLessHits >= 30.00 ? 'rgba(0, 105, 0)' : (trend?.pctThreeOrLessHits <= 20.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_or_less_walks_styles = {
                            color: (trend?.PctOneOrLessWalks >= 60.00 ? 'rgba(0, 105, 0)' : (trend?.PctOneOrLessWalks <= 50.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const zero_walks_styles = {
                            color: (trend?.pctZeroWalks >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroWalks <= 30.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const win_styles = {
                            color: (trend?.pctWin >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctWin <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }

                        return (
                            <tr key={trend.id}>
                                <td onClick={() => navigateToPitcherPage(trend.name, trend.pitcherID)}>{trend.name}</td>
                                <td style={teamStyles}>{trend.team}</td>
                                <td style={trend.backgroundColor}>{trend.matchupRating}</td>
                                <td>{trend.numberGames}</td>
                                <td style={five_plus_ip_styles}>{trend.pctFivePlusIP}%</td>
                                <td style={six_plus_ip_styles}>{trend.pctSixPlusIP}%</td>
                                <td style={seven_plus_ip_styles}>{trend.pctSevenPlusIP}%</td>
                                <td style={four_plus_k_styles}>{trend.pctFourPlusK}%</td>
                                <td style={five_plus_k_styles}>{trend.pctFivePlusK}%</td>
                                <td style={six_plus_k_styles}>{trend.pctSixPlusK}%</td>
                                <td style={seven_plus_k_styles}>{trend.pctSevenPlusK}%</td>
                                <td style={eight_plus_k_styles}>{trend.pctEightPlusK}%</td>
                                <td style={nine_plus_k_styles}>{trend.pctNinePlusK}%</td>
                                <td style={two_or_less_er_styles}>{trend.pctTwoOrLessER}%</td>
                                <td style={one_or_less_er_styles}>{trend.pctOneOrLessER}%</td>
                                <td style={zero_er_styles}>{trend.pctZeroER}%</td>
                                <td style={five_or_less_hits_styles}>{trend.pctFiveOrLessHits}%</td>
                                <td style={four_or_less_hits_styles}>{trend.pctFourOrLessHits}%</td>
                                <td style={three_or_less_hits_styles}>{trend.pctThreeOrLessHits}%</td>
                                <td style={one_or_less_walks_styles}>{trend.PctOneOrLessWalks}%</td>
                                <td style={zero_walks_styles}>{trend.pctZeroWalks}%</td>
                                <td style={win_styles}>{trend.pctWin}%</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
        )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
            )
        }
}

export default GoodPitcherMatchupRatingTrendsTable
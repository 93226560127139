import React, {useContext, useState, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import {useParams} from "react-router-dom"
import HitterResultsTable from "./HitterResultsTable";
import HitterGraph from "./HitterGraph";
import NotPremium from "./NotPremium";
import Logos from "./Logos";
import RatingDropDown from "./RatingDropDown";
import ColorLabels from "./ColorLabels";
import HomeGameBar from "./HomeGameBar";

function HitterPage() {
    const {state} = useContext(UserContext);
    //console.log('singleHitterState', state.singleHitter)
    const {name} = useParams()
    const decodedName = name.replace(/-/g, " ")
    //console.log('name', name)
    //const [firstName, lastName] = name.split(' ')
    const isUser = state.loggedIn ? true : false
    const [loading, setLoading] = useState(true)
    const [ratingTimeframe, setRatingTimeframe] = useState(false)
    const [relevantDailyHitter, setRelevantDailyHitter] = useState([])
    const [ratingTier, setRatingTier] = useState("")
    const [dataTimeframe, setDataTimeframe] = useState("ThreeYear")
    const [backendDataTimeframe, setBackendDataTimeframe] = useState("three_year")
    const [defaultOrCurrent, setDefaultOrCurrent] = useState("Default")
    //console.log('dataTimeframe on hitter page', dataTimeframe)

    useEffect(() => {
        if (Object.keys(state.singleHitter).length > 0) {
            setLoading(false)
        }
    }, [state.singleHitter])

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "CurrentSeason" : "ThreeYear")
        setBackendDataTimeframe(ratingTimeframe ? "current_season" : "three_year")
        setDefaultOrCurrent(ratingTimeframe ? "Current Season" : "Default")
    }, [ratingTimeframe])

    useEffect(() => {
        const relevantDailyStat = state[`daily${dataTimeframe}Stats`].find((stat) => stat.hitter_name === decodedName)
        setRelevantDailyHitter(relevantDailyStat)
    }, [state, dataTimeframe, decodedName])

    
    const primaryColor = state.teamColors[relevantDailyHitter?.hitter_team]?.primary || "transparent"
    const secondaryColor = state.teamColors[relevantDailyHitter?.hitter_team]?.secondary || "transparent"

    const opposingPitcherHand = `(${relevantDailyHitter?.throw_hand})`

    function handleChange() {
        setRatingTimeframe(prevRatingTimeframe => !prevRatingTimeframe)
    }

    const matchupRatingStyles = {
        backgroundColor: (relevantDailyHitter?.hit_matchup_rating >= 0.450 ? 'lightblue' : '') || (relevantDailyHitter?.hit_matchup_rating >= 0.400 && relevantDailyHitter?.hit_matchup_rating < 0.450 ? 'lightgreen' : '') || (relevantDailyHitter?.hit_matchup_rating > 0.333 && relevantDailyHitter?.hit_matchup_rating < 0.400 ? 'lightyellow' : '') || (relevantDailyHitter?.hit_matchup_rating <= 0.230 ? 'lightpink' : '')
    }
    const matchupWOBAStyles = {
        backgroundColor: (relevantDailyHitter?.hit_matchup_woba >= 0.400 ? 'lightblue' : '') || (relevantDailyHitter?.hit_matchup_woba < 0.400 && relevantDailyHitter?.hit_matchup_woba > 0.350 ? 'lightgreen' : '') || (relevantDailyHitter?.hit_matchup_woba >= 0.315 && relevantDailyHitter?.hit_matchup_woba < 0.350 ? 'lightyellow' : '') || (relevantDailyHitter?.hit_matchup_woba <= 0.300 ? 'lightpink' : '')
    }
    const matchupISOStyles = {
        backgroundColor: (relevantDailyHitter?.hit_matchup_iso >= 0.275 ? 'lightblue' : '') || (relevantDailyHitter?.hit_matchup_iso < 0.275 && relevantDailyHitter?.hit_matchup_iso >= 0.200 ? 'lightgreen' : '') || (relevantDailyHitter?.hit_matchup_iso > 0.160 && relevantDailyHitter?.hit_matchup_iso < 0.199 ? 'lightyellow' : '') || (relevantDailyHitter?.hit_matchup_iso <= 0.150 ? 'lightpink' : '')
    }
    const matchupHCStyles = {
        backgroundColor: (relevantDailyHitter?.hit_matchup_hard_contact >= 50.0 ? 'lightblue' : '') || (relevantDailyHitter?.hit_matchup_hard_contact < 50.0 && relevantDailyHitter?.hit_matchup_hard_contact >= 40.0 ? 'lightgreen' : '') || (relevantDailyHitter?.hit_matchup_hard_contact >= 33.0 && relevantDailyHitter?.hit_matchup_hard_contact < 40.0 ? 'lightyellow' : '') || (relevantDailyHitter?.hit_matchup_hard_contact <= 29.0 ? 'lightpink' : '')
    }
    const matchupFBStyles = {
        backgroundColor: (relevantDailyHitter?.hit_matchup_fb_pct >= 50.0 ? 'lightblue' : '') || (relevantDailyHitter?.hit_matchup_fb_pct < 50.0 && relevantDailyHitter?.hit_matchup_fb_pct >= 40.0 ? 'lightgreen' : '') || (relevantDailyHitter?.hit_matchup_fb_pct > 37.0 && relevantDailyHitter?.hit_matchup_fb_pct < 40.0 ? 'lightyellow' : '') || (relevantDailyHitter?.hit_matchup_fb_pct <= 30.0 ? 'lightpink' : '')
    }
    const wOBApercentChangeStyles = {
        color: (relevantDailyHitter?.hit_woba_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
    }
    const ISOpercentChangeStyles = {
        color: (relevantDailyHitter?.hit_iso_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
    }
    const HCpercentChangeStyles = {
        color: (relevantDailyHitter?.hit_hard_contact_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
    }
    const HRFBpercentChangeStyles = {
        color: (relevantDailyHitter?.hit_hr_per_fb_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
    }
    const FBpercentChangeStyles = {
        color: (relevantDailyHitter?.hit_fb_percent_change > 0 ? 'rgba(0, 100, 0)' : 'rgba(139, 0, 0)')
    }

    useEffect(() => {
        if (relevantDailyHitter?.hit_matchup_rating >= 0.45) {
            setRatingTier("Elite")
        }
        else if (relevantDailyHitter?.hit_matchup_rating >= 0.40 && relevantDailyHitter?.hit_matchup_rating < 0.45) {
            setRatingTier("Great")
        }
        else if (relevantDailyHitter?.hit_matchup_rating >= 0.333 && relevantDailyHitter?.hit_matchup_rating < 0.40) {
            setRatingTier("Above Average")
        }
        else if (relevantDailyHitter?.hit_matchup_rating <= 0.230) {
            setRatingTier("Poor")
        }
        else 
            setRatingTier("Average")
    }, [relevantDailyHitter])

    if (isUser)
    return (
        <div>
            <HomeGameBar />
            <Logos />
            <div style={{backgroundColor: primaryColor, border: `0.25rem solid ${secondaryColor}`, borderRadius: '0.75rem'}} className="hitterPageBox">
                <h1 className="hitterPageName">{decodedName}</h1>
                <div className="hitterPageDetails">
                    <h4>Bats: {relevantDailyHitter?.bat_hand} | Team: {relevantDailyHitter?.hitter_team}</h4>
                    <h4>Opponent: {relevantDailyHitter?.pitcher_team} | Opposing Pitcher: {relevantDailyHitter?.pitcher_name} {opposingPitcherHand}</h4>
                    {Object.keys(state.singleHitter).length > 0 && (<h4>Avg Default Matchup Rating: {state.singleHitter?.avg_three_year_rating[0].average_rating} | Avg Current Season Matchup Rating: {state.singleHitter?.avg_current_season_rating[0].average_rating} </h4>)}
                </div>
            </div>
            <br />
            <RatingDropDown ratingTimeframe={ratingTimeframe} handleChange={handleChange}/>
            <ColorLabels />
            <div style={{border: `0.25rem solid ${primaryColor}`, borderRadius: '0.75rem'}} className="hitterPageStats">
                <h3>Today's Matchup Stats</h3>
                <ul>
                    <li style={{fontWeight: 'bold', fontSize: '1.5rem', color: 'black'}}>Hitter</li>
                    <li>Matchup Rating
                        <span style={matchupRatingStyles}>{relevantDailyHitter?.hit_matchup_rating?.toFixed(3)}</span>
                    </li>
                    <li>Plate Appearances
                        <span>{relevantDailyHitter?.hit_pa}</span>
                    </li>
                    <li>wRC+
                        <span>{relevantDailyHitter?.hit_wrc_plus}</span>
                    </li>
                    <li>Matchup wOBA
                        <span style={matchupWOBAStyles}>{relevantDailyHitter?.hit_matchup_woba?.toFixed(3)}</span>
                    </li>
                    <li>wOBA Pct Change
                        <span style={wOBApercentChangeStyles}>{relevantDailyHitter?.hit_woba_percent_change === 0 ? '0.00%'
                        : relevantDailyHitter?.hit_woba_percent_change > 0 ? `+${relevantDailyHitter?.hit_woba_percent_change?.toFixed(2)}` 
                        : `${relevantDailyHitter?.hit_woba_percent_change?.toFixed(2)}`}%
                        </span>
                    </li>
                    <li>Matchup ISO
                        <span style={matchupISOStyles}>{relevantDailyHitter?.hit_matchup_iso?.toFixed(3)}</span>
                    </li>
                    <li>ISO Pct Change
                        <span style={ISOpercentChangeStyles}>{relevantDailyHitter?.hit_iso_percent_change === 0 ? '0.00%'
                        : relevantDailyHitter?.hit_iso_percent_change > 0 ? `+${relevantDailyHitter?.hit_iso_percent_change?.toFixed(2)}` 
                        : `${relevantDailyHitter?.hit_iso_percent_change?.toFixed(2)}`}%
                        </span>
                    </li>
                    <li>Matchup Hard Contact
                        <span style={matchupHCStyles}>{relevantDailyHitter?.hit_matchup_hard_contact?.toFixed(2)}%</span>
                    </li>
                    <li>Hard Contact Pct Change
                        <span style={HCpercentChangeStyles}>{relevantDailyHitter?.hit_hard_contact_percent_change === 0 ? '0.00%'
                        : relevantDailyHitter?.hit_hard_contact_percent_change > 0 ? `+${relevantDailyHitter?.hit_hard_contact_percent_change?.toFixed(2)}` 
                        : `${relevantDailyHitter?.hit_hard_contact_percent_change?.toFixed(2)}`}%
                        </span>
                    </li>
                    <li>Matchup HR/FB
                        <span>{relevantDailyHitter?.hit_matchup_hr_per_fb?.toFixed(2)}%</span>
                    </li>
                    <li>HR/FB Pct Change
                        <span style={HRFBpercentChangeStyles}>{relevantDailyHitter?.hit_hr_per_fb_percent_change === 0 ? '0.00%'
                        : relevantDailyHitter?.hit_hr_per_fb_percent_change > 0 ? `+${relevantDailyHitter?.hit_hr_per_fb_percent_change?.toFixed(2)}` 
                        : `${relevantDailyHitter?.hit_hr_per_fb_percent_change?.toFixed(2)}`}%
                        </span>
                    </li>
                    <li>Matchup Fly Ball Rate
                        <span style={matchupFBStyles}>{relevantDailyHitter?.hit_matchup_fb_pct?.toFixed(2)}%</span>
                    </li>
                    <li>Fly Ball Pct Change
                        <span style={FBpercentChangeStyles}>{relevantDailyHitter?.hit_fb_percent_change === 0 ? '0.00%'
                        : relevantDailyHitter?.hit_fb_percent_change > 0 ? `+${relevantDailyHitter?.hit_fb_percent_change?.toFixed(2)}` 
                        : `${relevantDailyHitter?.hit_fb_percent_change?.toFixed(2)}`}%
                        </span>
                    </li>
                </ul>
                <ul>
                    <li style={{fontWeight: 'bold', fontSize: '1.5rem', color: 'black'}}>Pitcher</li>
                    <li>Innings Pitched
                        <span>{relevantDailyHitter?.sp_ip}</span>
                    </li>
                    <li>xFIP
                        <span>{relevantDailyHitter?.sp_xfip}</span>
                    </li>
                    <li>wOBA 
                        <span>{relevantDailyHitter?.sp_woba}</span>
                    </li>
                    <li>ISO 
                        <span>{relevantDailyHitter?.sp_iso}</span>
                    </li>
                    <li>Hard Contact Rate 
                        <span>{relevantDailyHitter?.sp_hard_contact}%</span>
                    </li>
                    <li>Soft Contact Rate 
                        <span>{relevantDailyHitter?.sp_soft_contact}%</span>
                    </li>
                    <li>Strikeout Rate 
                        <span>{relevantDailyHitter?.sp_kpct}%</span>
                    </li>
                    <li>Walk Rate 
                        <span>{relevantDailyHitter?.sp_bbpct}%</span>
                    </li>
                    <li>Ground Ball Rate 
                        <span>{relevantDailyHitter?.sp_gbpct}%</span>
                    </li>
                    <li>HR/FB Rate 
                        <span>{relevantDailyHitter?.sp_hr_per_fb}%</span>
                    </li>
                    <li>Fly Ball Rate 
                        <span>{relevantDailyHitter?.sp_fbpct}%</span>
                    </li>
                </ul>
            </div>
            <br />
            {loading ? 
                (<div className="spinner"></div>) :
                (<div style={{border: `0.25rem solid ${secondaryColor}`, borderRadius: '0.75rem'}} className="hitterTrends">
                    {state.singleHitter?.[`last_10_results_${backendDataTimeframe}`].length > 0 && (
                        <>
                            <HitterResultsTable ratingTimeframe={ratingTimeframe} ratingTier={ratingTier} defaultOrCurrent={defaultOrCurrent} />
                            <HitterGraph ratingTimeframe={ratingTimeframe} ratingTier={ratingTier} defaultOrCurrent={defaultOrCurrent} />
                        </>
                    )}
                    {state.singleHitter?.[`last_10_results_${backendDataTimeframe}`].length === 0 && (
                        <>
                            <h2>No {defaultOrCurrent} {ratingTier} Ratings for {decodedName}</h2>
                        </>
                    )}
                </div>)
            }
        </div>
    )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
            )
        }
}

export default HitterPage
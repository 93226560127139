import React, { useContext, useRef, useState, useEffect } from "react"
import { UserContext } from '../Context/UserContext';
import * as d3 from 'd3';

function EliteStrikeoutPitcherGraph({strikeoutRatingTier, defaultOrCurrent, backendDataTimeframe}) {
    const {state} = useContext(UserContext);
    //console.log('backendDataTimeframe on EliteStrikeoutPitcherGraph', backendDataTimeframe)

    const svgRef = useRef(null)
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [fontSize, setFontSize] = useState(12);

    useEffect(() => {
        if (svgRef.current) {
            const width = svgRef.current.parentElement.offsetWidth;
            const height = 460;

            setDimensions({width, height})
        }
    }, [])

    useEffect(() => {
        if (state.singlePitcher) {

            const dataPoints = {
                "5+ IP": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_five_plus_innings,
                "6+ IP": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_six_plus_innings,
                "7+ IP": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_seven_plus_innings,
                "4+ K": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_with_over_three_strikeouts,
                "5+ K": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_with_over_four_strikeouts,
                "6+ K": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_with_over_five_strikeouts,
                "7+ K": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_with_over_six_strikeouts,
                "8+ K": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_with_over_seven_strikeouts,
                "9+ K": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_with_over_eight_strikeouts,
                "10+ K": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_with_over_nine_strikeouts,
                "Win": state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.percentage_with_win,
            }

            const svg = d3.select(svgRef.current)
            const margin = { top: 10, right: 50, bottom: 80, left: 45 };
            const width = dimensions.width - margin.left - margin.right;
            const height = dimensions.height - margin.top - margin.bottom;

            const y = d3.scaleBand()
                .rangeRound([0, height])
                .padding(0.25)
                .domain(Object.keys(dataPoints));

            const x = d3.scaleLinear()
                .rangeRound([0, width])
                .domain([0, 100]);

            svg.selectAll("*").remove(); // Clear previous render

            const defs = svg.append("defs");
            const filter = defs.append("filter")
                .attr("id", "drop-shadow")
                .attr("height", "130%");

            filter.append("feGaussianBlur")
                .attr("in", "SourceAlpha")
                .attr("stdDeviation", 2) // Adjust the blur radius to control the intensity of the shadow
                .attr("result", "blur");

            filter.append("feOffset")
                .attr("in", "blur")
                .attr("dx", 2) // Adjust the shadow's horizontal offset
                .attr("dy", 2) // Adjust the shadow's vertical offset
                .attr("result", "offsetBlur");

            filter.append("feComponentTransfer")
                .append("feFuncA")
                .attr("type", "linear")
                .attr("slope", 0.2); // Adjust the opacity of the shadow

            const feMerge = filter.append("feMerge");
            feMerge.append("feMergeNode");
            feMerge.append("feMergeNode").attr("in", "SourceGraphic");

            svg.append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)
                .selectAll(".bar")
                .data(Object.entries(dataPoints))
                .enter().append("rect")
                .attr("class", "bar")
                .attr("y", d => y(d[0]) + y.bandwidth() / 10)
                .attr("x", 0)
                .attr("height", y.bandwidth())
                .attr("width", d => Math.max(0, x(d[1])))
                .attr("fill", d => {
                    // Define thresholds and corresponding colors for each bar
                    const colorMap = {
                        "5+ IP": { threshold: 75, color: "#027148" },
                        "6+ IP": { threshold: 50, color: "#027148" },
                        "7+ IP": { threshold: 40, color: "#027148" },
                        "Under 3 ER": { threshold: 50, color: "#027148" },
                        "Under 2 ER": { threshold: 40, color: "#027148" },
                        "Under 1 ER": { threshold: 30, color: "#027148" },
                        "Under 6 Hits": { threshold: 50, color: "#027148" },
                        "Under 5 Hits": { threshold: 40, color: "#027148" },
                        "Under 2 BB": { threshold: 65, color: "#027148" },
                        "Under 1 BB": { threshold: 30, color: "#027148" },
                        "4+ K": { threshold: 80, color: "#027148" },
                        "5+ K": { threshold: 65, color: "#027148" },
                        "6+ K": { threshold: 50, color: "#027148" },
                        "7+ K": { threshold: 30, color: "#027148" },
                        "8+ K": { threshold: 30, color: "#027148" },
                        "9+ K": { threshold: 25, color: "#027148" },
                        "10+ K": { threshold: 10, color: "#027148" },
                        "Win": { threshold: 50, color: "#027148" },
                        "Loss": { threshold: 50, color: "#027148" },
                        // Define other bars with their respective thresholds and colors
                    };
            
                    // Find the color corresponding to the bar
                    if (colorMap[d[0]]) {
                        return +d[1] >= colorMap[d[0]].threshold ? colorMap[d[0]].color : "#8B0000"; // Default to red if not above threshold
                    }
                })
                .attr("filter", "url(#drop-shadow)");
            
            svg.append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)
                .selectAll(".bar-label")
                .data(Object.entries(dataPoints))
                .enter().append("text")
                .attr("class", "bar-label")
                .attr("y", d => y(d[0]) + y.bandwidth() / 1.25) //Swap x and y positions
                .attr("x", d => Math.max(0, x(d[1])) + 5) // Adjust the horizontal position of the text
                .attr("text-anchor", "start") // Align text to start
                .attr("dy", "0em") // Adjust vertical alignment
                .style("font-size", `${fontSize}px`) // Set font size dynamically
                .text(d => d[1] + "%"); // Display the data value on top of each bar

            svg.append("g")
                .attr("transform", `translate(${margin.left}, ${height + margin.top - 370})`) //change 500 number here to adjust height of y axis names
                .call(d3.axisLeft(y)) 
                .selectAll("text")
                .enter().append("text") 
                .style("font-size", '10.5px')
                .call(wrap, y.bandwidth());

            function wrap(text, width) {
                text.each(function() {
                    var text = d3.select(this),
                        words = text.text().split(/\s+/).reverse(), // Split text into words
                        word,
                        line = [],
                        lineNumber = 0,
                        lineHeight = 1.1, // Em units
                        y = text.attr("y"),
                        dy = parseFloat(text.attr("dy")),
                        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
            
                    while (word === words.pop()) {
                        line.push(word);
                        tspan.text(line.join(" "));
                        if (tspan.node().getComputedTextLength() > width) {
                            line.pop();
                            tspan.text(line.join(" "));
                            line = [word];
                            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                        }
                    }
                });
            }

            svg.append("g")
                .attr("transform", `translate(${margin.left}, ${height + margin.top})`)
                .call(d3.axisBottom(x));
        }

    }, [state.singlePitcher, backendDataTimeframe, dimensions, fontSize])

    useEffect(() => {
        const updateFontSize = () => {
            // Calculate font size based on the width of the SVG container
            const newFontSize = Math.min(dimensions.width / 20, 12); // Adjust 20 as needed for scaling
            setFontSize(newFontSize);
        };

        window.addEventListener('resize', updateFontSize);

        return () => window.removeEventListener('resize', updateFontSize);
    }, [dimensions]);

    if (state.singlePitcher)
    return (
        <div className="pitcherGraphContainer">
            <h2>{defaultOrCurrent} Success Rates</h2>
            <h2>Based on {strikeoutRatingTier} Matchup Strikeout Pct</h2>
            <p>{state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.number_elite_strikeout_rate_ratings} {strikeoutRatingTier} Strikeout Ratings</p>
            <p>Average Strikeout Rating for {strikeoutRatingTier} Tier: {state.singlePitcher?.[`elite_great_above_avg_rating_percentages_${backendDataTimeframe}_strikeouts`][0]?.average_elite_hitter_matchup_k_rate}%</p>
            <svg className="pitcherGraph" ref={svgRef}></svg>
        </div>
    )
    else return (
        <></>
    )
}

export default EliteStrikeoutPitcherGraph
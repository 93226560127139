export const initialState = {
    initialLoad: true,
    paymentStatus: false,
    user: {},
    loggedIn: false,
    games: [],
    authors: [],
    articles: [],
    dailyCurrentSeasonStats: [],
    dailyThreeYearStats: [],
    dailyPitcherCurrentSeasonStats: [],
    dailyPitcherThreeYearStats: [],
    teamTrends: [],
    singleHitter: [],
    singlePitcher: [],
    goodHitterTrendsThreeYear: [],
    goodHitterTrendsCurrentSeason: [],
    badHitterTrendsThreeYear: [],
    badHitterTrendsCurrentSeason: [],
    goodPitcherMatchupRatingTrendsThreeYear: [],
    goodPitcherMatchupRatingTrendsCurrentSeason: [],
    badPitcherMatchupRatingTrendsThreeYear: [],
    badPitcherMatchupRatingTrendsCurrentSeason: [],
    goodPitcherStrikeoutRatingTrendsThreeYear: [],
    goodPitcherStrikeoutRatingTrendsCurrentSeason: [],
    badPitcherStrikeoutRatingTrendsThreeYear: [],
    badPitcherStrikeoutRatingTrendsCurrentSeason: [],
    teamColors: {
        'ARI': {primary: 'rgba(167, 25, 48, 0.7)', secondary: 'rgba(227, 212, 173, 0.7)'},
        'ATL': {primary:'rgba(206, 17, 65, 0.7)', secondary: 'rgba(19, 39, 79, 0.7)'},
        'BAL': {primary:'rgba(223, 70, 1, 0.7)', secondary: 'rgba(39, 37, 31, 0.7)'},
        'BOS': {primary:'rgba(189, 48, 57, 0.7)', secondary: 'rgba(12, 35, 64, 0.7)'},
        'CHC': {primary:'rgba(14, 51, 134, 0.7)', secondary: 'rgba(204, 52, 51, 0.7)'},
        'CHW': {primary:'rgba(39, 37, 31, 0.7)', secondary: 'rgba(196,206,212, 0.7)'},
        'CIN': {primary:'rgba(198, 1, 31, 0.7)', secondary: 'rgba(39, 37, 31, 0.7)'},
        'CLE': {primary:'rgba(0, 56, 93, 0.7)', secondary: 'rgba(229, 0, 34, 0.7)'},
        'COL': {primary:'rgba(51, 51, 102, 0.7)', secondary: 'rgba(35, 31, 32, 0.7)'},
        'DET': {primary:'rgba(12, 35, 64, 0.7)', secondary: 'rgba(250, 70, 22, 0.7)'},
        'HOU': {primary:'rgba(0, 45, 98, 0.7)', secondary: 'rgba(235, 110, 31, 0.7)'},
        'KCR': {primary:'rgba(0, 70, 135, 0.7)', secondary: 'rgba(189, 155, 96, 0.7)'},
        'LAA': {primary:'rgba(186, 0, 33, 0.7)', secondary: 'rgba(0,50,99, 0.7)'},
        'LAD': {primary:'rgba(0, 90, 156, 0.7)', secondary: 'rgba(191, 192, 191, 0.7)'},
        'MIA': {primary:'rgba(0, 163, 224, 0.7)', secondary: 'rgba(239, 51, 64, 0.7)'},
        'MIL': {primary:'rgba(18, 40, 75, 0.7)', secondary: 'rgba(255, 197, 47, 0.7)'},
        'MIN': {primary:'rgba(0, 43, 92, 0.7)', secondary: 'rgba(211,17,69, 0.7)'},
        'NYM': {primary:'rgba(0, 45, 114, 0.7)', secondary: 'rgba(252, 89, 16, 0.7)'},
        'NYY': {primary:'rgba(0, 48, 135, 0.7)', secondary: 'rgba(196, 206, 211, 0.7)'},
        'OAK': {primary:'rgba(0, 56, 49, 0.7)', secondary: 'rgba(239, 178, 30, 0.7)'},
        'PHI': {primary:'rgba(232, 24, 40, 0.7)', secondary: 'rgba(0, 45, 114, 0.7)'},
        'PIT': {primary:'rgba(39, 37, 31, 0.7)', secondary: 'rgba(253,184,39, 0.7)'},
        'STL': {primary:'rgba(196, 30, 58, 0.7)', secondary: 'rgba(254, 219, 0, 0.7)'},
        'SDP': {primary:'rgba(47, 36, 29, 0.7)', secondary: 'rgba(255, 196, 37, 0.7)'},
        'SFG': {primary:'rgba(253, 90, 30, 0.7)', secondary: 'rgba(39, 37, 31, 0.7)'},
        'SEA': {primary:'rgba(12, 44, 86, 0.7)', secondary: 'rgba(0, 92, 92, 0.7)'},
        'TBR': {primary:'rgba(9, 44, 92, 0.7)', secondary: 'rgba(143, 188, 230, 0.7)'},
        'TEX': {primary:'rgba(0, 50, 120, 0.7)', secondary: 'rgba(192,17,31, 0.7)'},
        'TOR': {primary:'rgba(19, 74, 142, 0.7)', secondary: 'rgba(29, 45, 92, 0.7)'},
        'WSN': {primary:'rgba(171, 0, 3, 0.7)', secondary: 'rgba(20,34,90, 0.7)'},
    }
}

export function reducer(state, action) {
    switch (action.type) {
        case 'setPaymentStatus':
            return {
                ...state,
                paymentStatus: action.payload
            }
        case 'setUser':
            return {
                ...state,
                user: action.payload
            }
        case 'setLoad':
            return {
                ...state,
                initialLoad: false    
            }
        case 'login':
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            }
        case 'signup':
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            }
        case 'logout':
            return {
                ...state,
                user: {},
                loggedIn: false
            }
        case 'setLoggedIn':
            return {
                ...state,
                loggedIn: action.payload
            }
        case 'fetchGames':
            return {
                ...state,
                games: action.payload
            }
        case 'fetchTeamTrends':
            return {
                ...state,
                teamTrends: action.payload
            }
        case 'fetchAuthors':
            return {
                ...state,
                authors: action.payload
            }
        case 'fetchArticles':
            return {
                ...state,
                articles: action.payload
            }
        case 'fetchDailyCurrentSeasonStats':
            return {
                ...state,
                dailyCurrentSeasonStats: action.payload
            }
        case 'fetchDailyThreeYearStats':
            return {
                ...state,
                dailyThreeYearStats: action.payload
            }
        case 'fetchDailyPitcherCurrentSeasonStats':
            return {
                ...state,
                dailyPitcherCurrentSeasonStats: action.payload
            }
        case 'fetchDailyPitcherThreeYearStats':
            return {
                ...state,
                dailyPitcherThreeYearStats: action.payload
            }
        case 'fetchSingleHitter':
            return {
                ...state,
                singleHitter: action.payload
            }
        case 'fetchSinglePitcher':
            return {
                ...state,
                singlePitcher: action.payload
            }
        case 'fetchGoodHitterTrendsThreeYear':
            return {
                ...state,
                goodHitterTrendsThreeYear: action.payload,
            }
        case 'fetchGoodHitterTrendsCurrentSeason':
            return {
                ...state,
                goodHitterTrendsCurrentSeason: action.payload,
            }
        case 'fetchBadHitterTrendsThreeYear':
            return {
                ...state,
                badHitterTrendsThreeYear: action.payload,
            }
        case 'fetchBadHitterTrendsCurrentSeason':
            return {
                ...state,
                badHitterTrendsCurrentSeason: action.payload,
            }
        case 'fetchGoodPitcherMatchupRatingTrendsThreeYear':
            return {
                ...state,
                goodPitcherMatchupRatingTrendsThreeYear: action.payload,
            }
        case 'fetchGoodPitcherMatchupRatingTrendsCurrentSeason':
            return {
                ...state,
                goodPitcherMatchupRatingTrendsCurrentSeason: action.payload,
            }
        case 'fetchBadPitcherMatchupRatingTrendsThreeYear':
            return {
                ...state,
                badPitcherMatchupRatingTrendsThreeYear: action.payload,
            }
        case 'fetchBadPitcherMatchupRatingTrendsCurrentSeason':
            return {
                ...state,
                badPitcherMatchupRatingTrendsCurrentSeason: action.payload,
            }
        case 'fetchGoodPitcherStrikeoutRatingTrendsThreeYear':
            return {
                ...state,
                goodPitcherStrikeoutRatingTrendsThreeYear: action.payload,
            }
        case 'fetchGoodPitcherStrikeoutRatingTrendsCurrentSeason':
            return {
                ...state,
                goodPitcherStrikeoutRatingTrendsCurrentSeason: action.payload,
            }
        case 'fetchBadPitcherStrikeoutRatingTrendsThreeYear':
            return {
                ...state,
                badPitcherStrikeoutRatingTrendsThreeYear: action.payload,
            }
        case 'fetchBadPitcherStrikeoutRatingTrendsCurrentSeason':
            return {
                ...state,
                badPitcherStrikeoutRatingTrendsCurrentSeason: action.payload,
            }
        default:
            return state;
    }
}
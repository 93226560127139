import React, { useContext, useRef, useEffect, useState } from "react"
import { UserContext } from '../Context/UserContext';
import * as d3 from 'd3';

function HitterGraph({ratingTimeframe, ratingTier, defaultOrCurrent}) {
    const {state} = useContext(UserContext);
    const [dataTimeframe, setDataTimeframe] = useState("three_year")

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "current_season" : "three_year")
    }, [ratingTimeframe])

    const svgRef = useRef(null)
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [fontSize, setFontSize] = useState(12);

    useEffect(() => {
        if (svgRef.current) {
            const width = svgRef.current.parentElement.offsetWidth;
            const height = 450; // how thick the bars are

            setDimensions({width, height})
        }
    }, [])

    useEffect(() => {
        if (state.singleHitter) {

            const dataPoints = {
                "1+ Hit": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_one_plus_hit,
                "2+ Hit": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_two_plus_hit,
                "1B": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_one_plus_single,
                "2B": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_one_plus_double,
                "HR": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_one_plus_home_run,
                "2+ TB": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_two_plus_total_bases,
                "2+ HRR": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_two_plus_hits_runs_rbi,
                "3+ HRR": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_three_plus_hits_runs_rbi,
                "RBI": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_with_rbi,
                "Run": state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.percentage_with_run
            }

            const svg = d3.select(svgRef.current)
            const margin = { top: 20, right: 50, bottom: 65, left: 70 }; //changes how the graph lines up with the categories
            const width = dimensions.width - margin.left - margin.right;
            const height = dimensions.height - margin.top - margin.bottom;

            const y = d3.scaleBand()
                .rangeRound([0, height])
                .padding(0.30) //changes how wide the bars are
                .domain(Object.keys(dataPoints));

            const x = d3.scaleLinear()
                .rangeRound([0, width])
                .domain([0, 100]);

            svg.selectAll("*").remove(); // Clear previous render

            const defs = svg.append("defs");
            const filter = defs.append("filter")
                .attr("id", "drop-shadow")
                .attr("height", "130%");

            filter.append("feGaussianBlur")
                .attr("in", "SourceAlpha")
                .attr("stdDeviation", 2) // Adjust the blur radius to control the intensity of the shadow
                .attr("result", "blur");

            filter.append("feOffset")
                .attr("in", "blur")
                .attr("dx", 2) // Adjust the shadow's horizontal offset
                .attr("dy", 2) // Adjust the shadow's vertical offset
                .attr("result", "offsetBlur");

            filter.append("feComponentTransfer")
                .append("feFuncA")
                .attr("type", "linear")
                .attr("slope", 0.35); // Adjust the opacity of the shadow

            const feMerge = filter.append("feMerge");
            feMerge.append("feMergeNode");
            feMerge.append("feMergeNode").attr("in", "SourceGraphic");

            svg.append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)
                .selectAll(".bar")
                .data(Object.entries(dataPoints))
                .enter().append("rect")
                .attr("class", "bar")
                .attr("y", d => y(d[0]) + y.bandwidth() / 15) //adjusts where the bars sit, used to match up with labels on y-axis
                .attr("x", 0)
                .attr("height", y.bandwidth())
                .attr("width", d => Math.max(0, x(d[1])))
                .attr("fill", d => {
                    // Define thresholds and corresponding colors for each bar
                    const colorMap = {
                        "1+ Hit": { threshold: 60, color: "#027148" },
                        "2+ Hit": { threshold: 30, color: "#027148" },
                        "1B": { threshold: 52.5, color: "#027148" },
                        "2B": { threshold: 30, color: "#027148" },
                        "HR": { threshold: 25, color: "#027148" },
                        "2+ TB": { threshold: 52.5, color: "#027148" },
                        "2+ HRR": { threshold: 52.5, color: "#027148" },
                        "3+ HRR": { threshold: 30, color: "#027148" },
                        "RBI": { threshold: 52.5, color: "#027148" },
                        "Run": { threshold: 52.5, color: "#027148" },
                        // Define other bars with their respective thresholds and colors
                    };
            
                    // Find the color corresponding to the bar
                    if (colorMap[d[0]]) {
                        return +d[1] >= colorMap[d[0]].threshold ? colorMap[d[0]].color : "#8B0000"; // Default to red if not above threshold
                    }
                })
                .attr("filter", "url(#drop-shadow)");
            
            svg.append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`)
                .selectAll(".bar-label")
                .data(Object.entries(dataPoints))
                .enter().append("text")
                .attr("class", "bar-label")
                .attr("y", d => y(d[0]) + y.bandwidth() / 2) // Swap x and y positions
                .attr("x", d => Math.max(0, x(d[1])) + 5) // Adjust the horizontal position of the text after the graph aka the %
                .attr("text-anchor", "start") // Align text to start
                .attr("dy", "0.5em") // Adjust vertical alignment of text %
                .style("font-size", `${fontSize}px`) // Set font size dynamically
                .text(d => d[1] + "%"); // Display the data value on top of each bar

            svg.append("g")
                .attr("transform", `translate(${margin.left}, ${height + margin.top - 365})`) //adjusts position of y axis
                .call(d3.axisLeft(y)) 
                .selectAll("text")
                .enter().append("text") 
                .style("font-size", '10.5px')
                .call(wrap, y.bandwidth());

            function wrap(text, width) {
                text.each(function() {
                    var text = d3.select(this),
                        words = text.text().split(/\s+/).reverse(), // Split text into words
                        word,
                        line = [],
                        lineNumber = 0,
                        lineHeight = 1.1, // Em units
                        y = text.attr("y"),
                        dy = parseFloat(text.attr("dy")),
                        tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
            
                    while (word === words.pop()) {
                        line.push(word);
                        tspan.text(line.join(" "));
                        if (tspan.node().getComputedTextLength() > width) {
                            line.pop();
                            tspan.text(line.join(" "));
                            line = [word];
                            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
                        }
                    }
                });
            }

            svg.append("g")
                .attr("transform", `translate(${margin.left}, ${height + margin.top})`)
                .call(d3.axisBottom(x));
        }

    }, [state.singleHitter, dataTimeframe, dimensions, fontSize])

    useEffect(() => {
        const updateFontSize = () => {
            // Calculate font size based on the width of the SVG container
            const newFontSize = Math.min(dimensions.width / 20, 12); // Adjust 20 as needed for scaling
            setFontSize(newFontSize);
        };

        window.addEventListener('resize', updateFontSize);

        return () => window.removeEventListener('resize', updateFontSize);
    }, [dimensions]);

    if (state.singleHitter)
    return (
        <div className="hitterGraphContainer">
            <h2>{ratingTier} Rating Success Rates</h2>
            <p>{state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.number_elite_ratings} {defaultOrCurrent} {ratingTier} Ratings</p>
            <p>Avg {defaultOrCurrent} {ratingTier} Rating: {state.singleHitter?.[`elite_rating_percentages_${dataTimeframe}`][0]?.average_elite_rating}</p>
            <svg className="hitterGraph" ref={svgRef}></svg>
        </div>
    )
    else return (
        <></>
    )
}

export default HitterGraph
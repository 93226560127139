import React, {useState, useContext, useEffect} from "react";
import {useParams} from "react-router-dom"
import GameBar from "./GameBar";
import SingleGameTable from "./SingleGameTable";
import ColorLabels from "./ColorLabels";
import { UserContext } from '../Context/UserContext';
import GetPremium from "./GetPremium";
import Logos from "./Logos"
import Glossary from "./Glossary";
import TeamTrends from "./TeamTrends";
import "../styles/singleGameContainer.css"
import SignupLoginButtons from "./SignupLoginButtons";

function SingleGame() {
    const {home_team} = useParams()
    const {state} = useContext(UserContext);
    //console.log('params home team', home_team)
    const [ratingTimeframe, setRatingTimeframe] = useState(false) //false is default (last 3 seasons), true is current season only
    const [formattedDate, setFormattedDate] = useState("")
    const [showTeamInsights, setShowTeamInsights] = useState(true) //true shows them, false hides them
    //console.log('ratingTimeFrame', ratingTimeframe)
    const relevantGame = state.games?.find((game) => String(game?.home_team) === String(home_team))

    function handleChange() {
        setRatingTimeframe(!ratingTimeframe)
    }

    useEffect(() => {
        if (state?.games.length > 0) {
            // const statCreatedDate = state?.games[0].created_at
            // const [datePart] = statCreatedDate.split('T')
            // const [year, month, day] = datePart.split('-')
            //setFormattedDate(`${parseInt(month)}-${day}`)
            setFormattedDate(`9-29`)
        }
    }, [state.games])

    const isUser = state.loggedIn ? true : false
    //console.log('isUser', isUser)
    // const subscription = state.user.subscription
    // //console.log('sub', subscription)
    // const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    // //console.log('hasSub?', hasSub)
    // const today = new Date()
    // const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    //console.log('active sub?', isActiveSub)

    function renderHeader() {
        return (
            <>
                <Logos />
                <h2 className="singleGameHeader">MLB Hitter and Pitcher Ratings</h2>
                {formattedDate && (
                    <h3 style={{textAlign: "center", color: "#000055", marginTop: "-1rem", fontSize: "20px"}}>{relevantGame?.away_team} vs {relevantGame?.home_team} on {formattedDate}</h3>
                )}

            </>
        )
    }

    function renderTimeDropDown() {
        return (
            <div className="ratings-dropdown">
                <label >Select Dataset: </label>
                <select onChange={() => handleChange()}>
                    <option>Default (Last 3 Seasons)</option>
                    <option>Current Season Only</option>
                </select>
            </div>
        )
    }
    
    function handleShowTeamInsights() {
        setShowTeamInsights(prevShowTeamInsights => !prevShowTeamInsights)
    }

    if (!home_team) {
        return (
            <h1>Loading...</h1>
        )
    }
    else
        return (
            <div>
                {renderHeader()}
                <GameBar />
                {!isUser && (
                    <div className="notLoggedInSingleGameButtons">
                        <SignupLoginButtons />
                    </div>
                )}
                {isUser && (
                    <div>
                        {renderTimeDropDown()}
                        <br />
                        <div className="teamInisghtsBtnContainer">
                        <button id="teamInsightsBtn" onClick={handleShowTeamInsights}>{showTeamInsights ? "Hide Team Insights" : "See Team Insights"}</button>
                        </div>
                        {showTeamInsights && <TeamTrends relevantGame={relevantGame} ratingTimeframe={ratingTimeframe}/>}
                    </div>
                )}
                <ColorLabels />
                <Glossary />
                <br />
                <SingleGameTable homeTeam={home_team}  ratingTimeframe={ratingTimeframe}/>
            </div>
        )
}

export default SingleGame
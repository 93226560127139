import React, {useContext, useState, useMemo, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import ColorLabels from "./ColorLabels";
import Glossary from "./Glossary";
import {useNavigate} from "react-router-dom"

function HitterRatingsTable({ratingTimeframe}) {
    //console.log('time frame on hitter table', ratingTimeframe)
    const navigate = useNavigate()
    const {state, dispatch} = useContext(UserContext);
    const [dataTimeframe, setDataTimeframe] = useState("ThreeYear")
    //console.log('data time frame on hitter table', dataTimeframe)
    const [filteredHitterStats, setFilteredHitterStats] = useState(state[`daily${dataTimeframe}Stats`] || [])
    const [sortDirection, setSortDirection] = useState(false)
    const [sortState, setSortState] = useState("")

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "CurrentSeason" : "ThreeYear")
    }, [ratingTimeframe])

    useEffect(() => {
        const hitterStatsKey = `daily${dataTimeframe}Stats`
        if (Array.isArray(state[hitterStatsKey])) {
            setFilteredHitterStats(state[hitterStatsKey])
        }
        
    }, [state, dataTimeframe])

    function handleSort(e) {
        const category = e.target.textContent.toLowerCase().replace(" ", "_");
        if (e.target.textContent === "Matchup FB Rate") {
            setSortState("hit_matchup_fb_pct")
        }
        else if (e.target.textContent === "Matchup Hard Contact") {
            setSortState("hit_matchup_hard_contact")
        }
        else {
            const stateCategory = `hit_${category}`
            setSortState(stateCategory)
        }
        setSortDirection((prevDirection) => !prevDirection)
    }

    const sortedHitters = useMemo(() => {
        if (!filteredHitterStats || !Array.isArray(filteredHitterStats)) return []
        const sortedStats = [...(filteredHitterStats || [])]?.sort((a, b) => {
            const aValue = a?.[sortState] || 0;
            const bValue = b?.[sortState] || 0;
            const comparison = aValue - bValue;
            return sortDirection ? comparison : -comparison;
        });
        return sortedStats;
    }, [filteredHitterStats, sortState, sortDirection])

    function handleChange(e) {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined)
        setFilteredHitterStats(state[`daily${dataTimeframe}Stats`])
    else
        setFilteredHitterStats(state[`daily${dataTimeframe}Stats`]?.filter(stat => stat.hitter_name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    function fetchSingleHitterData(hitterID) {
        fetch(`/hitters/${hitterID}`)
            .then(res => res.json())
            .then(data => {
                if (data) {
                    dispatch({type: "fetchSingleHitter", payload: data})
                }
                else {
                    console.error("Error: Hitter data not found")
                }
            })
    }

    function navigateToHitterPage(hitterName, hitterID) {
        state.singleHitter = []
        const formattedName = hitterName.replace(/ /g, "-")
        fetchSingleHitterData(hitterID)
        navigate(`/hitter/${formattedName}`)
        window.scrollTo(0, 0)
    }

    return (
        <div className="ratings-container">
            <div className="searchBar">
                <label>Search A Hitter: </label>
                <input type="text" placeholder="Ex: Kris Bryant" onChange={(e) => handleChange(e)}></input>
            </div>
            <ColorLabels />
            <Glossary />
            <div className="table-container">
                <table className="current-ratings-table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Team</th>
                            <th>Bat Hand</th>
                            <th className="sortable" onClick={handleSort}>Matchup Rating</th>
                            <th>PA</th>
                            <th>WRC+</th>
                            <th>wOBA</th>
                            <th className="sortable" onClick={handleSort}>Matchup wOBA</th>
                            <th>wOBA Pct Change</th>
                            <th>ISO</th>
                            <th className="sortable" onClick={handleSort}>Matchup ISO</th>
                            <th>ISO Pct Change</th>
                            <th>Hard Contact</th>
                            <th className="sortable" onClick={handleSort}>Matchup Hard Contact</th>
                            <th>Hard Contact Pct Change</th>
                            <th>HR/FB</th>
                            <th>Matchup HR/FB</th>
                            <th>HR/FB Pct Change</th>
                            <th>FB Pct</th>
                            <th className="sortable" onClick={handleSort}>Matchup FB Rate</th>
                            <th>FB Pct Change</th>
                            <th>SP Name</th>
                            <th>SP Team</th>
                            <th>SP Throw Hand</th>
                            <th>SP IP</th>
                            <th>SP wOBA</th>
                            <th>SP ISO</th>
                            <th>SP Hard Contact Rate</th>
                            <th>SP Soft Contact Rate</th>
                            <th>SP K Rate</th>
                            <th>SP Walk Rate</th>
                            <th>SP xFIP</th>                        
                            <th>SP FB Rate</th>
                            <th>SP GB Rate</th>
                            <th>SP HR/FB</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedHitters?.map((stat) => {
                            const teamStyles = {backgroundColor: state.teamColors[stat.hitter_team].primary || "transparent"};
                            const matchupRatingStyles = {
                                backgroundColor: (stat.hit_matchup_rating >= 0.450 ? 'lightblue' : '') || (stat.hit_matchup_rating >= 0.400 && stat.hit_matchup_rating < 0.450 ? 'lightgreen' : '') || (stat.hit_matchup_rating > 0.333 && stat.hit_matchup_rating < 0.400 ? 'lightyellow' : '') || (stat.hit_matchup_rating <= 0.230 ? 'lightpink' : '')
                            }
                            const matchupWOBAStyles = {
                                backgroundColor: (stat.hit_matchup_woba >= 0.400 ? 'lightblue' : '') || (stat.hit_matchup_woba < 0.400 && stat.hit_matchup_woba > 0.350 ? 'lightgreen' : '') || (stat.hit_matchup_woba >= 0.315 && stat.hit_matchup_woba < 0.350 ? 'lightyellow' : '') || (stat.hit_matchup_woba <= 0.300 ? 'lightpink' : '')
                            }
                            const matchupISOStyles = {
                                backgroundColor: (stat.hit_matchup_iso >= 0.275 ? 'lightblue' : '') || (stat.hit_matchup_iso < 0.275 && stat.hit_matchup_iso >= 0.200 ? 'lightgreen' : '') || (stat.hit_matchup_iso > 0.160 && stat.hit_matchup_iso < 0.199 ? 'lightyellow' : '') || (stat.hit_matchup_iso <= 0.150 ? 'lightpink' : '')
                            }
                            const matchupHCStyles = {
                                backgroundColor: (stat.hit_matchup_hard_contact >= 50.0 ? 'lightblue' : '') || (stat.hit_matchup_hard_contact < 50.0 && stat.hit_matchup_hard_contact >= 40.0 ? 'lightgreen' : '') || (stat.hit_matchup_hard_contact >= 33.0 && stat.hit_matchup_hard_contact < 40.0 ? 'lightyellow' : '') || (stat.hit_matchup_hard_contact <= 29.0 ? 'lightpink' : '')
                            }
                            const matchupFBStyles = {
                                backgroundColor: (stat.hit_matchup_fb_pct >= 50.0 ? 'lightblue' : '') || (stat.hit_matchup_fb_pct < 50.0 && stat.hit_matchup_fb_pct >= 40.0 ? 'lightgreen' : '') || (stat.hit_matchup_fb_pct > 37.0 && stat.hit_matchup_fb_pct < 40.0 ? 'lightyellow' : '') || (stat.hit_matchup_fb_pct <= 30.0 ? 'lightpink' : '')
                            }
                            const wOBApercentChangeStyles = {
                                color: (stat.hit_woba_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_woba_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            const ISOpercentChangeStyles = {
                                color: (stat.hit_iso_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_iso_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            const HCpercentChangeStyles = {
                                color: (stat.hit_hard_contact_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_hard_contact_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            const HRFBpercentChangeStyles = {
                                color: (stat.hit_hr_per_fb_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_hr_per_fb_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            const FBpercentChangeStyles = {
                                color: (stat.hit_fb_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_fb_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            return (
                                <tr key={stat.id}>
                                    <td onClick={() => navigateToHitterPage(stat?.hitter_name, stat?.hitter_id)}>{stat?.hitter_name}</td>
                                    <td style={teamStyles}>{stat?.hitter_team}</td>
                                    <td>{stat?.bat_hand}</td>
                                    <td style={matchupRatingStyles}>{stat?.hit_matchup_rating?.toFixed(3)}</td>
                                    <td>{stat?.hit_pa}</td>
                                    <td>{stat?.hit_wrc_plus}</td>
                                    <td>{stat?.hit_woba}</td>
                                    <td style={matchupWOBAStyles}>{stat?.hit_matchup_woba?.toFixed(3)}</td>
                                    <td style={wOBApercentChangeStyles}>{stat?.hit_woba_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_iso}</td>
                                    <td style={matchupISOStyles}>{stat?.hit_matchup_iso?.toFixed(3)}</td>
                                    <td style={ISOpercentChangeStyles}>{stat?.hit_iso_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_hard_contact_pct}%</td>
                                    <td style={matchupHCStyles}>{stat?.hit_matchup_hard_contact?.toFixed(1)}%</td>
                                    <td style={HCpercentChangeStyles}>{stat?.hit_hard_contact_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_hr_per_fb}%</td>
                                    <td>{stat?.hit_matchup_hr_per_fb?.toFixed(1)}%</td>
                                    <td style={HRFBpercentChangeStyles}>{stat?.hit_hr_per_fb_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_fb_pct}%</td>
                                    <td style={matchupFBStyles}>{stat?.hit_matchup_fb_pct?.toFixed(1)}%</td>
                                    <td style={FBpercentChangeStyles}>{stat?.hit_fb_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.pitcher_name}</td>
                                    <td>{stat?.pitcher_team}</td>
                                    <td>{stat?.throw_hand}</td>
                                    <td>{stat?.sp_ip}</td>
                                    <td>{stat?.sp_woba}</td>
                                    <td>{stat?.sp_iso}</td>
                                    <td>{stat?.sp_hard_contact.toFixed(1)}%</td>
                                    <td>{stat?.sp_soft_contact.toFixed(1)}%</td>
                                    <td>{stat?.sp_kpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_bbpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_xfip}</td>
                                    <td>{stat?.sp_fbpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_gbpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_hr_per_fb.toFixed(1)}%</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default HitterRatingsTable
import React, {useContext, useMemo, useState, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import NotPremium from "./NotPremium";
import "../styles/hitterTrendsTable.css"
import {useNavigate} from "react-router-dom"

function BadHitterTrendsTable({ratingTimeframe}) {
    const {state, dispatch} = useContext(UserContext);
    //console.log('state', state)
    const isUser = state.loggedIn ? true : false
    const navigate = useNavigate()
    const [sortDirection, setSortDirection] = useState(false)
    const [sortState, setSortState] = useState("")
    const [dataTimeframe, setDataTimeframe] = useState("ThreeYear")

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "CurrentSeason" : "ThreeYear")
    }, [ratingTimeframe])
    //console.log('datatimeframe', dataTimeframe)

    const trendsByTimeFrame = state?.[`badHitterTrends${dataTimeframe}`]
    const relDailyHitterByTimeFrame = state?.[`daily${dataTimeframe}Stats`]

    function getMatchupRating(hitID) {
        const rel_hitter = relDailyHitterByTimeFrame?.find((stat) => stat.hitter_id === hitID)
        //console.log('rel_hitter', rel_hitter)
        const matchupRatingStyles = {
            backgroundColor: (rel_hitter?.hit_matchup_rating >= 0.450 ? 'lightblue' : '') || (rel_hitter?.hit_matchup_rating >= 0.400 && rel_hitter?.hit_matchup_rating < 0.450 ? 'lightgreen' : '') || (rel_hitter?.hit_matchup_rating > 0.333 && rel_hitter?.hit_matchup_rating < 0.400 ? 'lightyellow' : '') || (rel_hitter?.hit_matchup_rating <= 0.230 ? 'lightpink' : '')
        }

        if (rel_hitter)
            return {ratingStyle: matchupRatingStyles, rating: rel_hitter.hit_matchup_rating.toFixed(3)}
        else
            return {ratingStyle: matchupRatingStyles, rating:"rating not found"} 
    }

    const hitterRatings = useMemo(() => {
        return trendsByTimeFrame?.map((trend) => ({
            id: trend.id,
            name: trend.hitter_name,
            team: trend.team,
            matchupRating: getMatchupRating(trend.hitter_id).rating,
            numberGames: trend.num_games_sample_size,
            pctZeroHits: trend.pct_zero_hits_mr_category,
            pctOneHit: trend.pct_one_hit_mr_category,
            pctZeroTotalBases: trend.pct_zero_total_bases_mr_category,
            pctOneOrLessTotalBases: trend.pct_one_or_less_total_bases_mr_category,
            pctZeroHitsRunsRBI: trend.pct_zero_hits_runs_rbi_mr_category,
            pctOneOrLessHitsRunsRBI: trend.pct_one_or_less_hits_runs_rbi_mr_category,
            pctZeroSingles: trend.pct_zero_single_mr_category,
            pctZeroDoubles: trend.pct_zero_double_mr_category,
            pctZeroHomeRuns: trend.pct_zero_home_runs_mr_category,
            pctZeroRuns: trend.pct_zero_run_mr_category,
            pctZeroRBIs: trend.pct_zero_rbi_mr_category,
            backgroundColor: getMatchupRating(trend.hitter_id).ratingStyle
        }));
    }, [trendsByTimeFrame])

    const [filteredHitters, setFilteredHitters] = useState(hitterRatings)

    useEffect(() => {
        setFilteredHitters(hitterRatings)
    }, [hitterRatings])

    function handleSort(e) {
        //console.log('e.target.textContent', e.target.textContent)
        const target = e.target.textContent.slice(0, e.target.textContent.length - 2).trim()
        //console.log('target', target)
        switch (target) {
            case "Name":
                setSortState("name")
                break;
            case "Team":
                setSortState("team")
                break;
            case "Matchup Rating":
                setSortState("matchupRating");
                break;
            case "Games":
                setSortState("numberGames");
                break;
            case "Under 0.5 Hits":
                setSortState("pctZeroHits");
                break;
            case "Under 1.5 Hits":
                setSortState("pctOneHit");
                break;
            case "Under 0.5 Total Bases":
                setSortState("pctZeroTotalBases");
                break;
            case "Under 1.5 Total Bases":
                setSortState("pctOneOrLessTotalBases");
                break;
            case "Under 0.5 Hits Runs RBI":
                setSortState("pctZeroHitsRunsRBI");
                break;
            case "Under 1.5 Hits Runs RBI":
                setSortState("pctOneOrLessHitsRunsRBI");
                break;
            case "Under 0.5 1B":
                setSortState("pctZeroSingles");
                break;
            case "Under 0.5 2B":
                setSortState("pctZeroDoubles");
                break;
            case "Under 0.5 HR":
                setSortState("pctZeroHomeRuns");
                break;
            case "Under 0.5 Run":
                setSortState("pctZeroRuns");
                break;
            case "Under 0.5 RBI":
                setSortState("pctZeroRBIs");
                break;
            default:
                setSortState("")
        }
        
        setSortDirection((prevDirection) => !prevDirection)
    }

    const sortedTrends = useMemo(() => {
        //if (!filteredHitterStats || !Array.isArray(filteredHitterStats)) return []
        const sortedStats = [...(filteredHitters)]?.sort((a, b) => {
            const aValue = a?.[sortState] || "";
            const bValue = b?.[sortState] || "";

            if (typeof aValue === "string" && typeof bValue === "string") {
                return sortDirection 
                    ? aValue.localeCompare(bValue)  // Ascending order for strings
                    : bValue.localeCompare(aValue); // Descending order for strings
            }

            const numA = Number(aValue) || 0;
            const numB = Number(bValue) || 0;
            return sortDirection ? numA - numB : numB - numA;
        });
        //console.log('sortedStats', sortedStats)
        //console.log('sortState', sortState)
        //console.log('sortDirection', sortDirection)
        return sortedStats;
    }, [filteredHitters, sortDirection, sortState])

    function handleSearch(e) {
        const inputValue = e.target.value.toLowerCase('')
        if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            setFilteredHitters(hitterRatings)
        }
        else {
            setFilteredHitters(hitterRatings.filter((rating) => rating.name.toLowerCase().includes(inputValue)))
        }
    }

    function fetchSingleHitterData(hitterID) {
        fetch(`/hitters/${hitterID}`)
            .then(res => res.json())
            .then(data => {
                if (data) {
                    dispatch({type: "fetchSingleHitter", payload: data})
                }
                else {
                    console.error("Error: Hitter data not found")
                }
            })
    }

    function navigateToHitterPage(hitterName, hitterID) {
        state.singleHitter = []
        const encodedName = encodeURIComponent(hitterName)
        fetchSingleHitterData(hitterID)
        navigate(`/hitter/${encodedName}`)
        window.scrollTo(0, 0)
    }

    if (isUser)
    return (
        <div className="hitterTrendsContainerTable">
            <div className="searchBar">
                <label>Search A Hitter: </label>
                <input type="text" placeholder="Ex: Kyle Tucker" onChange={handleSearch}></input>
            </div>
            <br />
            <table>
                <thead>
                    <tr>
                        <th onClick={handleSort}>Name<span>↕️</span></th>
                        <th onClick={handleSort}>Team<span>↕️</span></th>
                        <th onClick={handleSort}>Matchup Rating<span>↕️</span></th>
                        <th onClick={handleSort}>Games<span>↕️</span></th>
                        <th onClick={handleSort}>Under 0.5 Hits<span>↕️</span></th>
                        <th onClick={handleSort}>Under 1.5 Hits<span>↕️</span></th>
                        <th onClick={handleSort}>Under 0.5 Total Bases<span>↕️</span></th>
                        <th onClick={handleSort}>Under 1.5 Total Bases<span>↕️</span></th>
                        <th onClick={handleSort}>Under 0.5 Hits Runs RBI<span>↕️</span></th>
                        <th onClick={handleSort}>Under 1.5 Hits Runs RBI<span>↕️</span></th>
                        <th onClick={handleSort}>Under 0.5 1B<span>↕️</span></th>
                        <th onClick={handleSort}>Under 0.5 2B<span>↕️</span></th>
                        <th onClick={handleSort}>Under 0.5 HR<span>↕️</span></th>
                        <th onClick={handleSort}>Under 0.5 Run<span>↕️</span></th>
                        <th onClick={handleSort}>Under 0.5 RBI<span>↕️</span></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedTrends.map((trend) => {
                        const teamStyles = {backgroundColor: state.teamColors[trend.team].primary || "transparent"}
                        const zero_hit_styles = {
                            color: (trend?.pctZeroHits >= 60.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroHits <= 50.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_or_less_hits_styles = {
                            color: (trend?.pctOneHit >= 25.00 ? 'rgba(0, 105, 0)' : (trend?.pctOneHit <= 15.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const zero_bases_styles = {
                            color: (trend?.pctZeroTotalBases >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroTotalBases <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_or_less_bases_styles = {
                            color: (trend?.pctOneOrLessTotalBases >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctOneOrLessTotalBases <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const zero_hrr_styles = {
                            color: (trend?.pctZeroHitsRunsRBI >= 40.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroHitsRunsRBI <= 30.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_or_less_hrr_styles = {
                            color: (trend?.pctOneOrLessHitsRunsRBI >= 55.00 ? 'rgba(0, 105, 0)' : (trend?.pctOnePlusSingle <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const zero_singles_styles = {
                            color: (trend?.pctZeroSingles >= 22.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroSingles <= 15.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const zero_doubles_styles = {
                            color: (trend?.pctZeroDoubles >= 20.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroDoubles <= 10.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const zero_home_run_styles = {
                            color: (trend?.pctZeroHomeRuns >= 55.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroHomeRuns <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const zero_run_styles = {
                            color: (trend?.pctZeroRuns >= 55.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroRuns <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const zero_rbi_styles = {
                            color: (trend?.pctZeroRBIs >= 55.00 ? 'rgba(0, 105, 0)' : (trend?.pctZeroRBIs <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }

                        return (
                            <tr key={trend.id}>
                                <td onClick={() => navigateToHitterPage(trend.name, trend.hitterID)}>{trend.name}</td>
                                <td style={teamStyles}>{trend.team}</td>
                                <td style={trend.backgroundColor}>{trend.matchupRating}</td>
                                <td>{trend.numberGames}</td>
                                <td style={zero_hit_styles}>{trend.pctZeroHits}%</td>
                                <td style={one_or_less_hits_styles}>{trend.pctOneHit}%</td>
                                <td style={zero_bases_styles}>{trend.pctZeroTotalBases}%</td>
                                <td style={one_or_less_bases_styles}>{trend.pctOneOrLessTotalBases}%</td>
                                <td style={zero_hrr_styles}>{trend.pctZeroHitsRunsRBI}%</td>
                                <td style={one_or_less_hrr_styles}>{trend.pctOneOrLessHitsRunsRBI}%</td>
                                <td style={zero_singles_styles}>{trend.pctZeroSingles}%</td>
                                <td style={zero_doubles_styles}>{trend.pctZeroDoubles}%</td>
                                <td style={zero_home_run_styles}>{trend.pctZeroHomeRuns}%</td>
                                <td style={zero_run_styles}>{trend.pctZeroRuns}%</td>
                                <td style={zero_rbi_styles}>{trend.pctZeroRBIs}%</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
            )
        }
}

export default BadHitterTrendsTable
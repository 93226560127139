import React, {useContext, useState, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import {useParams} from "react-router-dom"
import NotPremium from "./NotPremium";
import Logos from "./Logos";
import RatingDropDown from "./RatingDropDown";
import ColorLabels from "./ColorLabels";
import HomeGameBar from "./HomeGameBar";
import PitcherMatchupRatingResults from "./PitcherMatchupRatingResults";
import ElitePitcherMatchupRatingGraph from "./ElitePitcherMatchupRatingGraph";
import PoorPitcherMatchupRatingGraph from "./PoorPitcherMatchupRatingGraph";
import PitcherStrikeoutRatingResults from "./PitcherStrikeoutRatingResults";
import EliteStrikeoutPitcherGraph from "./EliteStrikeoutPitcherGraph";
import PoorStrikeoutPitcherGraph from "./PoorStrikeoutPitcherGraph";

function PitcherPage() {
    const {state} = useContext(UserContext);
    //console.log('singlePitcherState', state.singlePitcher)
    const {name} = useParams()
    //console.log('name', name)
    //const [firstName, lastName] = name.split(' ')
    const isUser = state.loggedIn ? true : false
    const [loading, setLoading] = useState(true)
    const [ratingTimeframe, setRatingTimeframe] = useState(false)
    const [relevantDailyPitcher, setRelevantDailyPitcher] = useState([])
    const [relevantGameHitters, setRelevantGameHitters] = useState([])
    const [ratingTier, setRatingTier] = useState("")
    const [strikeoutRatingTier, setStrikeoutRatingTier] = useState("")
    const [dataTimeframe, setDataTimeframe] = useState("ThreeYear")
    const [backendDataTimeframe, setBackendDataTimeframe] = useState("three_year")
    const [defaultOrCurrent, setDefaultOrCurrent] = useState("Default")

    useEffect(() => {
        if (Object.keys(state.singlePitcher).length > 0) {
            setLoading(false)
        }
    }, [state.singlePitcher])

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "CurrentSeason" : "ThreeYear")
        setBackendDataTimeframe(ratingTimeframe ? "current_season" : "three_year")
        setDefaultOrCurrent(ratingTimeframe ? "Current Season" : "Default")
    }, [ratingTimeframe])

    useEffect(() => {
        const relevantDailyStat = state[`dailyPitcher${dataTimeframe}Stats`].find((stat) => stat.pitcher_name === name)
        setRelevantDailyPitcher(relevantDailyStat)

        const relHitters = state[`daily${dataTimeframe}Stats`].filter((stat) => stat.pitcher_name === name).slice(0,9)
        setRelevantGameHitters(relHitters)
    }, [state, dataTimeframe, name])
    
    const primaryColor = state.teamColors[relevantDailyPitcher?.pitcher_team]?.primary || "transparent"
    const secondaryColor = state.teamColors[relevantDailyPitcher?.pitcher_team]?.secondary || "transparent"

    function handleChange() {
        setRatingTimeframe(prevRatingTimeframe => !prevRatingTimeframe)
    }

    const groundballRateStyles = {
        backgroundColor: (relevantDailyPitcher?.avg_hitter_matchup_gb_pct >= 50.0 ? 'lightblue' : '') || (relevantDailyPitcher?.avg_hitter_matchup_gb_pct >= 46.0 && relevantDailyPitcher?.avg_hitter_matchup_gb_pct < 50.0 ? 'lightgreen' : '') || (relevantDailyPitcher?.avg_hitter_matchup_gb_pct > 43.0 && relevantDailyPitcher?.avg_hitter_matchup_gb_pct < 46.0 ? 'lightyellow' : '') || (relevantDailyPitcher?.avg_hitter_matchup_gb_pct <= 37.0 ? 'lightpink' : '')
    }
    const hardContactStyles = {
        backgroundColor: (relevantDailyPitcher?.avg_hitter_matchup_hard_contact <= 26.0 ? 'lightblue' : '') || (relevantDailyPitcher?.avg_hitter_matchup_hard_contact >= 26.01 && relevantDailyPitcher?.avg_hitter_matchup_hard_contact < 29.0 ? 'lightgreen' : '') || (relevantDailyPitcher?.avg_hitter_matchup_hard_contact >= 29.01 && relevantDailyPitcher?.avg_hitter_matchup_hard_contact < 32.0 ? 'lightyellow' : '') || (relevantDailyPitcher?.avg_hitter_matchup_hard_contact >= 36.5 ? 'lightpink' : '')
    }
    const isoStyles = {
        backgroundColor: (relevantDailyPitcher?.avg_hitter_matchup_iso <= 0.129 ? 'lightblue' : '') || (relevantDailyPitcher?.avg_hitter_matchup_iso >= 0.130 && relevantDailyPitcher?.avg_hitter_matchup_iso < 0.140 ? 'lightgreen' : '') || (relevantDailyPitcher?.avg_hitter_matchup_iso >= 0.140 && relevantDailyPitcher?.avg_hitter_matchup_iso < 0.150 ? 'lightyellow' : '') || (relevantDailyPitcher?.avg_hitter_matchup_iso >= 0.175 ? 'lightpink' : '')
    }
    const wobaStyles = {
        backgroundColor: (relevantDailyPitcher?.avg_hitter_matchup_woba <= 0.280 ? 'lightblue' : '') || (relevantDailyPitcher?.avg_hitter_matchup_woba > 0.280 && relevantDailyPitcher?.avg_hitter_matchup_woba < 0.300 ? 'lightgreen' : '') || (relevantDailyPitcher?.avg_hitter_matchup_woba >= 0.300 && relevantDailyPitcher?.avg_hitter_matchup_woba < 0.330 ? 'lightyellow' : '') || (relevantDailyPitcher?.avg_hitter_matchup_woba >= 0.350 ? 'lightpink' : '')
    }
    const matchupRatingStyles = {
        backgroundColor: (relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent <= 5.63 ? 'lightblue' : '') || (relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent > 5.63 && relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent <= 5.85 ? 'lightgreen' : '') || (relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent > 5.85 && relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent < 6.05 ? 'lightyellow' : '') || (relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent >= 6.4 ? 'lightpink' : '')
    }
    const strikeoutRateStyles = {
        backgroundColor: (relevantDailyPitcher?.avg_hitter_matchup_k_pct >= 30.0 ? 'lightblue' : '') || (relevantDailyPitcher?.avg_hitter_matchup_k_pct >= 26.0 && relevantDailyPitcher?.avg_hitter_matchup_k_pct < 30.0 ? 'lightgreen' : '') || (relevantDailyPitcher?.avg_hitter_matchup_k_pct >= 23.0 && relevantDailyPitcher?.avg_hitter_matchup_k_pct < 26.0 ? 'lightyellow' : '') || (relevantDailyPitcher?.avg_hitter_matchup_k_pct <= 20.0 ? 'lightpink' : '')
    }

    useEffect(() => {
        if (relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent <= 5.63) {
            setRatingTier("Elite")
        }
        else if (relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent > 5.63 && relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent <= 5.85) {
            setRatingTier("Great")
        }
        else if (relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent > 5.85 && relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent <= 6.05) {
            setRatingTier("Above Average")
        }
        else if (relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent >= 6.40) {
            setRatingTier("Poor")
        }
        else 
            setRatingTier("Average")
    }, [relevantDailyPitcher])

    useEffect(() => {
        if (relevantDailyPitcher?.avg_hitter_matchup_k_pct >= 30.0) {
            setStrikeoutRatingTier("Elite")
        }
        else if (relevantDailyPitcher?.avg_hitter_matchup_k_pct >= 26.0 && relevantDailyPitcher?.avg_hitter_matchup_k_pct < 30.0) {
            setStrikeoutRatingTier("Great")
        }
        else if (relevantDailyPitcher?.avg_hitter_matchup_k_pct >= 23.0 && relevantDailyPitcher?.avg_hitter_matchup_k_pct < 26.0) {
            setStrikeoutRatingTier("Above Average")
        }
        else if (relevantDailyPitcher?.avg_hitter_matchup_k_pct <= 20.0) {
            setStrikeoutRatingTier("Poor")
        }
        else 
            setStrikeoutRatingTier("Average")
    }, [relevantDailyPitcher])

    if (isUser)
    return (
        <div>
            <HomeGameBar />
            <Logos />
            <div style={{backgroundColor: primaryColor, border: `0.25rem solid ${secondaryColor}`, borderRadius: '0.75rem'}} className="pitcherPageBox">
                <h1 className="pitcherPageName">{name}</h1>
                <div className="pitcherPageDetails">
                    <h4>Throws: {relevantDailyPitcher?.throw_hand} | Team: {relevantDailyPitcher?.pitcher_team} | Opponent: {relevantDailyPitcher?.hitter_team}</h4>
                    {Object.keys(state.singlePitcher).length > 0 && (<h4>Avg Default Matchup Rating: {state.singlePitcher?.avg_three_year_rating[0].average_rating} | Avg Current Season Matchup Rating: {state.singlePitcher?.avg_current_season_rating[0].average_rating} </h4>)}
                </div>
            </div>
            <br />
            <RatingDropDown ratingTimeframe={ratingTimeframe} handleChange={handleChange}/>
            <ColorLabels />
            <div style={{border: `0.25rem solid ${primaryColor}`, borderRadius: '0.75rem'}} className="pitcherPageStats">
                <h3>Today's Matchup Stats</h3>
                <ul>
                    <li style={{fontWeight: 'bold', fontSize: '1.5rem', color: 'black'}}>Pitcher</li>
                    <li>Matchup Rating
                        <span style={matchupRatingStyles}>{relevantDailyPitcher?.pitcher_matchup_rating_thirty_percent}</span>
                    </li>
                    <li>Innings Pitched
                        <span>{relevantDailyPitcher?.pitcher_ip}</span>
                    </li>
                    <li>Avg Hitter Matchup Rating
                        <span>{relevantDailyPitcher?.avg_hitter_matchup_rating}</span>
                    </li>
                    <li>Avg Hitter Matchup wOBA
                        <span style={wobaStyles}>{relevantDailyPitcher?.avg_hitter_matchup_woba}</span>
                    </li>
                    <li>Avg Hitter Matchup ISO
                        <span style={isoStyles}>{relevantDailyPitcher?.avg_hitter_matchup_iso}</span>
                    </li>
                    <li>Avg Hitter Matchup Hard Contact
                        <span style={hardContactStyles}>{relevantDailyPitcher?.avg_hitter_matchup_hard_contact}%</span>
                    </li>
                    <li>Avg Hitter Matchup Strikeout Rate
                        <span style={strikeoutRateStyles}>{relevantDailyPitcher?.avg_hitter_matchup_k_pct}%</span>
                    </li>
                    <li>Avg Hitter Matchup Ground Ball Rate
                        <span style={groundballRateStyles}>{relevantDailyPitcher?.avg_hitter_matchup_gb_pct}%</span>
                    </li>
                    <li>Opp. Elite Rated Hitters
                        <span>{relevantDailyPitcher?.num_elite_rated_hitters}</span>
                    </li>
                    <li>Opp. Poor Rated Hitters
                        <span>{relevantDailyPitcher?.num_poor_rated_hitters}</span>
                    </li>
                    <li>Opp. Hitters Above MLB Avg Strikeout Rate
                        <span>{relevantDailyPitcher?.num_hitters_above_avg_k_pct}</span>
                    </li>
                    <li>Opp. Hitters Above MLB Avg Ground Ball Rate
                        <span>{relevantDailyPitcher?.num_hitters_above_avg_gb_pct}</span>
                    </li>
                </ul>
                <ul>
                    <li style={{fontWeight: 'bold', fontSize: '1.5rem', color: 'black'}}>Top 9 Opponent Matchup Ratings</li>
                    {relevantGameHitters.map((hit) => {
                        const hitterMatchupRatingStyles = {
                            backgroundColor: (hit?.hit_matchup_rating >= 0.450 ? 'lightblue' : '') || (hit?.hit_matchup_rating >= 0.400 && hit?.hit_matchup_rating < 0.450 ? 'lightgreen' : '') || (hit?.hit_matchup_rating > 0.333 && hit?.hit_matchup_rating < 0.400 ? 'lightyellow' : '') || (hit?.hit_matchup_rating <= 0.230 ? 'lightpink' : '')
                        }
                        
                        return (
                            <li key={hit.hitter_id}>{hit?.hitter_name}
                                <span style={hitterMatchupRatingStyles}>{hit?.hit_matchup_rating?.toFixed(3)}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
            <br />
            {loading ? 
                (<div className="spinner"></div>) :
                (<div style={{border: `0.25rem solid ${secondaryColor}`, borderRadius: '0.75rem'}} className="pitcherTrends">
                    {/* below should tell me last 10 results for both timeframes and whatever ratingTier they are in */}
                    {state.singlePitcher?.[`last_10_results_${backendDataTimeframe}_matchup_rating`].length > 0 && (
                        <div className="pitcherMatchupRatingSection">
                            <PitcherMatchupRatingResults ratingTier={ratingTier} ratingTimeframe={ratingTimeframe} defaultOrCurrent={defaultOrCurrent} backendDataTimeframe={backendDataTimeframe} />
                        </div>
                    )}
                    <div className="pitcherMatchupRatingSection">
                        {(ratingTier === "Elite" || ratingTier === "Great" || ratingTier === "Above Average") && (<ElitePitcherMatchupRatingGraph ratingTier={ratingTier} ratingTimeframe={ratingTimeframe} defaultOrCurrent={defaultOrCurrent} backendDataTimeframe={backendDataTimeframe} />)}
                        {(ratingTier === "Average" || ratingTier === "Poor") && (<PoorPitcherMatchupRatingGraph ratingTier={ratingTier} ratingTimeframe={ratingTimeframe} defaultOrCurrent={defaultOrCurrent} backendDataTimeframe={backendDataTimeframe}/>)}
                    </div>
                    {/* need strikeout rating results/graphs below for both timeframes and whatever ratingTier they are in */}
                    {state.singlePitcher?.[`last_10_results_${backendDataTimeframe}_strikeouts`].length > 0 && (
                        <div className="pitcherStrikeoutRatingSection">
                            <PitcherStrikeoutRatingResults strikeoutRatingTier={strikeoutRatingTier} ratingTimeframe={ratingTimeframe} defaultOrCurrent={defaultOrCurrent} backendDataTimeframe={backendDataTimeframe}/>
                        </div>
                    )}
                    <div className="pitcherStrikeoutRatingSection">
                        {(strikeoutRatingTier === "Elite" || strikeoutRatingTier === "Great" || strikeoutRatingTier === "Above Average") && (<EliteStrikeoutPitcherGraph strikeoutRatingTier={strikeoutRatingTier} ratingTimeframe={ratingTimeframe} defaultOrCurrent={defaultOrCurrent} backendDataTimeframe={backendDataTimeframe}/>)}
                        {(strikeoutRatingTier === "Average" || strikeoutRatingTier === "Poor") && (<PoorStrikeoutPitcherGraph strikeoutRatingTier={strikeoutRatingTier} ratingTimeframe={ratingTimeframe} defaultOrCurrent={defaultOrCurrent} backendDataTimeframe={backendDataTimeframe}/>)}
                    </div>
                    {state.singlePitcher?.[`last_10_results_${backendDataTimeframe}_matchup_rating`].length === 0 && (
                        <>
                            <h2>No {defaultOrCurrent} {ratingTier} Ratings for {name}</h2>
                        </>
                    )}
                    {state.singlePitcher?.[`last_10_results_${backendDataTimeframe}_strikeouts`] === 0 && (
                        <>
                            <h2>No {defaultOrCurrent} {ratingTier} Strikeout Ratings for {name}</h2>
                        </>
                    )}
                </div>)
            }
        </div>
    )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
            )
        }
}

export default PitcherPage
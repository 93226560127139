import React, {useContext, useState} from "react";
import { UserContext } from '../Context/UserContext';
import NotPremium from "./NotPremium";
import Logos from "./Logos";
import GameBar from "./GameBar";
import RatingDropDown from "./RatingDropDown";
import ColorLabels from "./ColorLabels";
import GoodPitcherMatchupRatingTrendsTable from "./GoodPitcherMatchupRatingTrendsTable";
import BadPitcherMatchupRatingTrendsTable from "./BadPitcherMatchupRatingTrendsTable";
import GoodPitcherStrikeoutRatingTrendsTable from "./GoodPitcherStrikeoutRatingTrends";
import BadPitcherStrikeoutRatingTrendsTable from "./BadPitcherStrikeoutRatingTrends";
import "../styles/pitcherTrendsContainer.css"

function PitcherTrendsContainer() {
    const {state} = useContext(UserContext);
    //console.log('singleHitterState', state.singleHitter)
    const [ratingTimeframe, setRatingTimeframe] = useState(false)
    const [ratingState, setRatingState] = useState("Good Matchup Rating Trends")
    const isUser = state.loggedIn ? true : false

    function handleChange() {
        setRatingTimeframe(prevTimeframe => !prevTimeframe)
    }

    function handleRatingChange(e) {
        setRatingState(e.target.value)
    }

    if (isUser)
    return (
        <div>
            <Logos />
            <br />
            <GameBar />
            <RatingDropDown ratingTimeframe={ratingTimeframe} handleChange={handleChange}/>
            <div className="overUnderDropdownContainer">
                <label>Select Trend: </label>
                <select onChange={handleRatingChange} value={ratingState}>
                    <option value="Good Matchup Rating Trends">Good Matchup Rating Trends</option>
                    <option value="Bad Matchup Rating Trends">Bad Matchup Rating Trends</option>
                    <option value="Good Strikeout Rating Trends">Good Strikeout Rating Trends</option>
                    <option value="Bad Strikeout Rating Trends">Bad Strikeout Rating Trends</option>
                </select>
            </div>
            <ColorLabels />
            <br />
            <div style={{textAlign: "center", listStyle: "none"}}>
                <li>Note: All trends are based on today's matchup rating category.</li>
                <li>Example: Number of games with an elite rating or great rating, etc.</li>
            </div>
            {ratingState === "Good Matchup Rating Trends" && <GoodPitcherMatchupRatingTrendsTable ratingTimeframe={ratingTimeframe} />}
            {ratingState === "Bad Matchup Rating Trends" && <BadPitcherMatchupRatingTrendsTable ratingTimeframe={ratingTimeframe} />}
            {ratingState === "Good Strikeout Rating Trends" && <GoodPitcherStrikeoutRatingTrendsTable ratingTimeframe={ratingTimeframe} />}
            {ratingState === "Bad Strikeout Rating Trends" && <BadPitcherStrikeoutRatingTrendsTable ratingTimeframe={ratingTimeframe} />}
        </div>
    )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
            )
        }
}

export default PitcherTrendsContainer
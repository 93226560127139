import React, {useContext, useMemo, useState, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import NotPremium from "./NotPremium";
import "../styles/hitterTrendsTable.css"
import {useNavigate} from "react-router-dom"

function GoodHitterTrendsTable({ratingTimeframe}) {
    const {state, dispatch} = useContext(UserContext);
    //console.log('state', state)
    const navigate = useNavigate()
    const isUser = state.loggedIn ? true : false
    const [sortDirection, setSortDirection] = useState(false)
    const [sortState, setSortState] = useState("")
    const [dataTimeframe, setDataTimeframe] = useState("ThreeYear")

    useEffect(() => {
        setDataTimeframe(ratingTimeframe ? "CurrentSeason" : "ThreeYear")
    }, [ratingTimeframe])
    //console.log('datatimeframe', dataTimeframe)

    const trendsByTimeFrame = state?.[`goodHitterTrends${dataTimeframe}`]
    const relDailyHitterByTimeFrame = state?.[`daily${dataTimeframe}Stats`]

    function getMatchupRating(hitID) {
        const rel_hitter = relDailyHitterByTimeFrame?.find((stat) => stat.hitter_id === hitID)
        //console.log('rel_hitter', rel_hitter)
        const matchupRatingStyles = {
            backgroundColor: (rel_hitter?.hit_matchup_rating >= 0.450 ? 'lightblue' : '') || (rel_hitter?.hit_matchup_rating >= 0.400 && rel_hitter?.hit_matchup_rating < 0.450 ? 'lightgreen' : '') || (rel_hitter?.hit_matchup_rating > 0.333 && rel_hitter?.hit_matchup_rating < 0.400 ? 'lightyellow' : '') || (rel_hitter?.hit_matchup_rating <= 0.230 ? 'lightpink' : '')
        }

        return {ratingStyle: matchupRatingStyles, rating: rel_hitter.hit_matchup_rating.toFixed(3)}
    }

    const hitterRatings = useMemo(() => {
        return trendsByTimeFrame.map((trend) => ({
            id: trend.id,
            hitterID: trend.hitter_id,
            name: trend.hitter_name,
            team: trend.team,
            matchupRating: getMatchupRating(trend.hitter_id).rating,
            numberGames: trend.num_games_sample_size,
            pctOnePlusHit: trend.pct_one_plus_hit_mr_category,
            pctTwoPlusHit: trend.pct_two_plus_hit_mr_category,
            pctTwoPlusTotalBases: trend.pct_two_plus_total_bases_mr_category,
            pctTwoPlusHitsRunsRBI: trend.pct_two_plus_hits_runs_rbi_mr_category,
            pctThreePlusHitsRunsRBI: trend.pct_three_plus_hits_runs_rbi_mr_category,
            pctOnePlusSingle: trend.pct_one_plus_single_mr_category,
            pctOnePlusDouble: trend.pct_one_plus_double_mr_category,
            pctOnePlusHomeRun: trend.pct_one_plus_home_run_mr_category,
            pctOnePlusRun: trend.pct_one_plus_run_mr_category,
            pctOnePlusRBI: trend.pct_one_plus_rbi_mr_category,
            backgroundColor: getMatchupRating(trend.hitter_id).ratingStyle
        }));
    }, [trendsByTimeFrame])

    //console.log(hitterRatings)
    const [filteredHitters, setFilteredHitters] = useState(hitterRatings)

    useEffect(() => {
        setFilteredHitters(hitterRatings)
    }, [hitterRatings])

    function handleSort(e) {
        //console.log('e.target.textContent', e.target.textContent)
        const target = e.target.textContent.slice(0, e.target.textContent.length - 2).trim()
        //console.log('target', target)
        switch (target) {
            case "Name":
                setSortState("name")
                break;
            case "Team":
                setSortState("team")
                break;
            case "Matchup Rating":
                setSortState("matchupRating");
                break;
            case "Games":
                setSortState("numberGames");
                break;
            case "1+ Hit":
                setSortState("pctOnePlusHit");
                break;
            case "2+ Hits":
                setSortState("pctTwoPlusHit");
                break;
            case "2+ Total Bases":
                setSortState("pctTwoPlusTotalBases");
                break;
            case "2+ Hits Runs RBI":
                setSortState("pctTwoPlusHitsRunsRBI");
                break;
            case "3+ Hits Runs RBI":
                setSortState("pctThreePlusHitsRunsRBI");
                break;
            case "1+ 1B":
                setSortState("pctOnePlusSingle");
                break;
            case "1+ 2B":
                setSortState("pctOnePlusDouble");
                break;
            case "1+ HR":
                setSortState("pctOnePlusHomeRun");
                break;
            case "1+ Run":
                setSortState("pctOnePlusRun");
                break;
            case "1+ RBI":
                setSortState("pctOnePlusRBI");
                break;
            default:
                setSortState("")
        }
        
        setSortDirection((prevDirection) => !prevDirection)
    }

    const sortedTrends = useMemo(() => {
        //if (!filteredHitterStats || !Array.isArray(filteredHitterStats)) return []
        const sortedStats = [...(filteredHitters)]?.sort((a, b) => {
            const aValue = a?.[sortState] || "";
            const bValue = b?.[sortState] || "";

            if (typeof aValue === "string" && typeof bValue === "string") {
                return sortDirection 
                    ? aValue.localeCompare(bValue)  // Ascending order for strings
                    : bValue.localeCompare(aValue); // Descending order for strings
            }

            const numA = Number(aValue) || 0;
            const numB = Number(bValue) || 0;
            return sortDirection ? numA - numB : numB - numA;
        });
        //console.log('sortedStats', sortedStats)
        //console.log('sortState', sortState)
        //console.log('sortDirection', sortDirection)
        return sortedStats;
    }, [filteredHitters, sortDirection, sortState])
    //console.log('sortedTrends', sortedTrends)

    function handleSearch(e) {
        const inputValue = e.target.value.toLowerCase('')
        if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
            setFilteredHitters(hitterRatings)
        }
        else {
            setFilteredHitters(hitterRatings.filter((rating) => rating.name.toLowerCase().includes(inputValue)))
        }
    }

    function fetchSingleHitterData(hitterID) {
        fetch(`/hitters/${hitterID}`)
            .then(res => res.json())
            .then(data => {
                if (data) {
                    dispatch({type: "fetchSingleHitter", payload: data})
                }
                else {
                    console.error("Error: Hitter data not found")
                }
            })
    }

    function navigateToHitterPage(hitterName, hitterID) {
        state.singleHitter = []
        const encodedName = encodeURIComponent(hitterName)
        fetchSingleHitterData(hitterID)
        navigate(`/hitter/${encodedName}`)
        window.scrollTo(0, 0)
    }

    if (isUser)
    return (
        <div className="hitterTrendsContainerTable">
            <div className="searchBar">
                <label>Search A Hitter: </label>
                <input type="text" placeholder="Ex: Kyle Tucker" onChange={handleSearch}></input>
            </div>
            <br />
            <table>
                <thead>
                    <tr>
                        <th onClick={handleSort}>Name<span>↕️</span></th>
                        <th onClick={handleSort}>Team<span>↕️</span></th>
                        <th onClick={handleSort}>Matchup Rating<span>↕️</span></th>
                        <th onClick={handleSort}>Games<span>↕️</span></th>
                        <th onClick={handleSort}>1+ Hit<span>↕️</span></th>
                        <th onClick={handleSort}>2+ Hits<span>↕️</span></th>
                        <th onClick={handleSort}>2+ Total Bases<span>↕️</span></th>
                        <th onClick={handleSort}>2+ Hits Runs RBI<span>↕️</span></th>
                        <th onClick={handleSort}>3+ Hits Runs RBI<span>↕️</span></th>
                        <th onClick={handleSort}>1+ 1B<span>↕️</span></th>
                        <th onClick={handleSort}>1+ 2B<span>↕️</span></th>
                        <th onClick={handleSort}>1+ HR<span>↕️</span></th>
                        <th onClick={handleSort}>1+ Run<span>↕️</span></th>
                        <th onClick={handleSort}>1+ RBI<span>↕️</span></th>
                    </tr>
                </thead>
                <tbody>
                    {sortedTrends.map((trend) => {
                        const teamStyles = {backgroundColor: state.teamColors[trend.team].primary || "transparent"}
                        const one_plus_hit_styles = {
                            color: (trend?.pctOnePlusHit >= 60.00 ? 'rgba(0, 105, 0)' : (trend?.pctOnePlusHit <= 50.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const two_plus_hit_styles = {
                            color: (trend?.pctTwoPlusHit >= 25.00 ? 'rgba(0, 105, 0)' : (trend?.pctTwoPlusHit <= 15.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const two_plus_bases_styles = {
                            color: (trend?.pctTwoPlusTotalBases >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctTwoPlusTotalBases <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const two_plus_hrr_styles = {
                            color: (trend?.pctTwoPlusHitsRunsRBI >= 50.00 ? 'rgba(0, 105, 0)' : (trend?.pctTwoPlusHitsRunsRBI <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const three_plus_hrr_styles = {
                            color: (trend?.pctThreePlusHitsRunsRBI >= 40.00 ? 'rgba(0, 105, 0)' : (trend?.pctThreePlusHitsRunsRBI <= 30.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_plus_single_styles = {
                            color: (trend?.pctOnePlusSingle >= 55.00 ? 'rgba(0, 105, 0)' : (trend?.pctOnePlusSingle <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_plus_double_styles = {
                            color: (trend?.pctOnePlusDouble >= 22.00 ? 'rgba(0, 105, 0)' : (trend?.pctOnePlusDouble <= 15.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_plus_hr_styles = {
                            color: (trend?.pctOnePlusHomeRun >= 20.00 ? 'rgba(0, 105, 0)' : (trend?.pctOnePlusHomeRun <= 10.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_plus_run_styles = {
                            color: (trend?.pctOnePlusRun >= 55.00 ? 'rgba(0, 105, 0)' : (trend?.pctOnePlusRun <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }
                        const one_plus_rbi_styles = {
                            color: (trend?.pctOnePlusRBI >= 55.00 ? 'rgba(0, 105, 0)' : (trend?.pctOnePlusRBI <= 40.00 ? 'rgba(180, 0, 0)' : 'rgba(0, 0, 0)'))
                        }

                        return (
                            <tr key={trend.id}>
                                <td onClick={() => navigateToHitterPage(trend.name, trend.hitterID)}>{trend.name}</td>
                                <td style={teamStyles}>{trend.team}</td>
                                <td style={trend.backgroundColor}>{trend.matchupRating}</td>
                                <td>{trend.numberGames}</td>
                                <td style={one_plus_hit_styles}>{trend.pctOnePlusHit}%</td>
                                <td style={two_plus_hit_styles}>{trend.pctTwoPlusHit}%</td>
                                <td style={two_plus_bases_styles}>{trend.pctTwoPlusTotalBases}%</td>
                                <td style={two_plus_hrr_styles}>{trend.pctTwoPlusHitsRunsRBI}%</td>
                                <td style={three_plus_hrr_styles}>{trend.pctThreePlusHitsRunsRBI}%</td>
                                <td style={one_plus_single_styles}>{trend.pctOnePlusSingle}%</td>
                                <td style={one_plus_double_styles}>{trend.pctOnePlusDouble}%</td>
                                <td style={one_plus_hr_styles}>{trend.pctOnePlusHomeRun}%</td>
                                <td style={one_plus_run_styles}>{trend.pctOnePlusRun}%</td>
                                <td style={one_plus_rbi_styles}>{trend.pctOnePlusRBI}%</td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
            )
        }
}

export default GoodHitterTrendsTable